<template>
  <div class="myBanner column flex_center white_color" :style="{backgroundImage: 'url('+ bg +')',backgroundSize: '100% 100%'}">
    <div class="ft48 bolder">{{ title }}</div>
    <div class="ft24 mt20">{{ enTitle }}</div>
    <div class="line6 mt30"></div>
  </div>
</template>
<script>
import { otherVal } from '@/api/app'
export default {
  data() {
    return {
      bg:''
    }
  },
  props:{
    title:String,
    enTitle:String,
    current: Number,
  },
  mounted() {
    this.fetchOtherVal()
  },
  methods: {
    async fetchOtherVal() {
      let {data} = await otherVal()
      if(this.current == 1){
        this.bg = data.data.img_one
      }
      if(this.current == 2){
        this.bg = data.data.img_two
      }
      if(this.current == 3){
        this.bg = data.data.img_three
      }
      if(this.current == 4){
        this.bg = data.data.img_four
      }
    },
  },
}
</script>
<style lang="scss">
  .myBanner {
    width: 100vw;
    height: 820px;
  }
  @media screen and (min-width: 1800px) and (max-width: 2000px) {
    .myBanner {
      height: 700px;
    }
  }
  @media screen and (min-width: 1500px) and (max-width: 1800px) {
    .myBanner {
      height: 600px;
    }
  }
  @media screen and (min-width: 750px) and (max-width: 1500px) {
    .myBanner {
      height: 500px;
    }
  }
  @media screen and (max-width: 750px){
    .myBanner {
      height: 36.4583vw;
    }
  }
</style>
