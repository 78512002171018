<template>
  <div class="navbar bg_nav row justify_sb align_center">
    <img
      class="logoimg ml360"
      :src="logo"
      alt=""
      @click="handleShow(0)"
    />

    <ul class="row mr360">
      <li
        class="row flex_center white_color ft18"
        v-for="(item, index) in navList"
        :key="item.id"
        :class="current == index ? 'bg_li' : ''"
        @click="handleShow(index)"
      >
        <span>{{ item.catname }}</span>
      </li>
    </ul>
  </div>
</template>
<script>
import {getIndex, otherVal} from "@/api/app"
export default {
  data() {
    return {
      navList: [],
      logo: "",
    }
  },
  props: {
    current: Number,
  },
  mounted() {
    this.fetchOtherVal()
    this.fetchIndex()
  },
  methods: {
    handleShow(index) {
      this.$emit("onHandleShow", index)
    },
    async fetchOtherVal() {
      let {data} = await otherVal()
      this.logo = data.data.logo
    },
    async fetchIndex() {
      let {data} = await getIndex()
      this.navList = data.data.nav_list
    },
  },
}
</script>
<style lang="scss">
.navbar {
  width: 100vw;
  height: 88px;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 666;
  .logoimg {
    width: 160px;
    height: 62px;
  }
  > ul {
    height: 88px;
    > li {
      height: 100%;
      padding: 0px 36px;
      transition: all 0.3s linear;
    }
    > li:hover {
      cursor: pointer;
      background: rgba(69, 129, 251, 0.3);
      position: relative;
      transition: all 0.3s linear;
    }
  }
}
@media screen and (max-width: 750px){
  .navbar{
    height: 5.2vw;
    .logoimg {
      width: 8.3333vw;
      height: 3.2292vw;
    }
    > ul {
      height: 5.2vw;
      > li{
        padding: 0px 1.875vw;
      }
    }
  }
}
</style>
