/* 
  就是把不同模块的 state attion。。。。放到一个对象里面去
*/
import {getIndex, bottomNav, otherVal} from "@/api/app"
/*
 * @Descripttion:
 * @version:
 * @Author:
 * @Date: 2022-03-06 11:23:28
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-03-14 13:50:38
 */
const state = {
  otherVal:{},//其他数据
  bottomNav:{},//底部导航
  navList:[],//导航栏
}

// getters计算属性
const getters = {
  getDemoValue: (state) => state.cityCode,
}

// actions异步请求处理
const actions = {
  async fetchOtherVal() {
    let {data} = await otherVal()
    console.log("otherVal", data.data)
    state.otherVal = data.data
  },
  async fetchBottomNav() {
    let {data} = await bottomNav()
    console.log("bottomNav", data.data)
    state.bottomNav = data.data
  },
  async fetchIndex({commit}) {
    let {data} = await getIndex()
    console.log("index", data.data)
    commit('setNavList',data.data.nav_list)
  },
}

// mutations主要用来操作state
const mutations = {
  setNavList (state, navList) {
    state.navList = navList;
    console.log('setNavList', state.navList);
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
