import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import "./permisstion"

import ElementUI from 'element-ui';

//import 'element-ui/lib/theme-chalk/index.css';
import './styles/element-variables.scss';
import '../src/assets/css/common.css';
import 'animate.css';

import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)

import MyNav from '../src/components/MyNav'
import SonBanner from '../src/components/SonBanner'
import MyOther from '../src/components/MyOther'
import MyFooter from '../src/components/MyFooter'
Vue.component('MyNav', MyNav);
Vue.component('SonBanner', SonBanner);
Vue.component('MyOther', MyOther);
Vue.component('MyFooter', MyFooter);

Vue.use(ElementUI)

Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
