<template>
  <div class="main primary_color">
    <!-- nav -->
    <my-nav
      :current="current"
      @onHandleShow="handleShow"
    ></my-nav>

    <!-- 新媒体营销 -->
    <son-banner :title="title" :enTitle="enTitle" :current="current"></son-banner>

    <!-- 子nav -->
    <div class="navs bg_w">
      <ul class="row justify_sb ml360 mr360">
        <li
          class="column align_center justify_sb"
          v-for="(item) in list"
          :key="item.id"
          @click="changeNav(item.id)"
        >
          <img
            class="wh80 mt140"
            :src="navNum == item.id ? item.image : item.icon"
            alt=""
          />
          <div class="">{{ item.catname }}</div>
          <div class="line6" v-if="navNum == item.id"></div>
          <div
            class="line6"
            style="background: transparent;"
            v-else
          ></div>
        </li>
      </ul>
    </div>

    <!-- 内容 -->
    <div class="content row flex_center">
      <div class="row align_center justify_sb">
        <div class="left">
          <div class="row align_center">
            <img class="wh80" :src="content.thumb" alt="" />
            <span class="ft36 ml30">{{ content.title }}</span>
          </div>
          <div class="mt30">
            <div class="ft32 mt20">
              {{ content.description }}
            </div>
          </div>
          <button class="btn_p mt60 cursor ft20" @click="handleCollaborate">立即投放</button>
        </div>
        <div class="right row justify_sb">
          <img
            class="wow animate__animated animate__zoomIn"
            :src="content.images?content.images[0]:''"
            alt=""
            :class="navNum % 2 == 0 ? 'mb70' : 'mt70'"
            data-wow-duration="0.8s"
            data-wow-delay="0.2s"
            data-wow-iteration="1"
          />
          <img
            class="ml58 wow animate__animated animate__zoomIn"
            :src="content.images?content.images[1]:''"
            alt=""
            :class="navNum % 2 == 0 ? 'mt70' : 'mb70'"
            data-wow-duration="0.8s"
            data-wow-delay="0.4s"
            data-wow-iteration="1"
          />
        </div>
      </div>
    </div>

    <!-- other -->
    <my-other :current="current"></my-other>

    <!-- footer -->
    <my-footer :current="current" @onScrollTo="scroll"></my-footer>
  </div>
</template>

<script>
import { newMediaList } from '@/api/app'
export default {
  data() {
    return {
      content: {},
      list: [
        // {
        //   name: "快手营销",
        //   icon: require("../assets/img/newmedia/icon_kuaishouyingxiao.png"),
        //   content: {
        //     name: "快手APP",
        //     icon: require("../assets/img/newmedia/icon_select_kuaishouyingxiao.png"),
        //     text: [
        //       "深入了解快手用户的行为和兴趣",
        //       "精准定向投放、创新广告形式",
        //     ],
        //     imgs: [
        //       require("../assets/img/newmedia/kuaishou1.png"),
        //       require("../assets/img/newmedia/kuaishou2.png"),
        //     ],
        //   },
        // },
        // {
        //   name: "抖音营销",
        //   icon: require("../assets/img/newmedia/icon_douyinyingxiao.png"),
        //   content: {
        //     name: "抖音APP",
        //     icon: require("../assets/img/newmedia/icon_select_douyinyingxiao.png"),
        //     text: [
        //       "增加品牌曝光、关注用户增长",
        //       "品牌信息传递受众，提高用户转化率",
        //     ],
        //     imgs: [
        //       require("../assets/img/newmedia/douyin1.png"),
        //       require("../assets/img/newmedia/douyin2.png"),
        //     ],
        //   },
        // },
        // {
        //   name: "小红书营销",
        //   icon: require("../assets/img/newmedia/icon_xiaohongshuyingxiao.png"),
        //   content: {
        //     name: "小红书APP",
        //     icon: require("../assets/img/newmedia/icon_select_xiaohongshu.png"),
        //     text: [
        //       "打造品牌形象，吸引潜在客户",
        //       "利用大数据模型定位投放目标用户",
        //     ],
        //     imgs: [
        //       require("../assets/img/newmedia/xiaohongshu1.png"),
        //       require("../assets/img/newmedia/xiaohongshu2.png"),
        //     ],
        //   },
        // },
        // {
        //   name: "微信营销",
        //   icon: require("../assets/img/newmedia/icon_weixinyingxiao.png"),
        //   content: {
        //     name: "微信APP",
        //     icon: require("../assets/img/newmedia/icon_select_weixinyingxiao.png"),
        //     text: [
        //       "微信公众号运营",
        //       "微信朋友圈广告投放",
        //       "微信小程序资讯投放",
        //     ],
        //     imgs: [
        //       require("../assets/img/newmedia/weixin1.png"),
        //       require("../assets/img/newmedia/weixin2.png"),
        //     ],
        //   },
        // },
        // {
        //   name: "微博营销",
        //   icon: require("../assets/img/newmedia/icon_weiboyingxiao.png"),
        //   content: {
        //     name: "微博APP",
        //     icon: require("../assets/img/newmedia/icon_select_weiboyingxiao.png"),
        //     text: [
        //       "深入了解微博用户的行为和兴趣",
        //       "精准定向投放、创新广告形式",
        //     ],
        //     imgs: [
        //       require("../assets/img/newmedia/weibo1.png"),
        //       require("../assets/img/newmedia/weibo2.png"),
        //     ],
        //   },
        // },
        // {
        //   name: "全网营销",
        //   icon: require("../assets/img/newmedia/icon_quanwangyingxiao.png"),
        //   content: {
        //     name: "快手APP",
        //     icon: require("../assets/img/newmedia/icon_select_quanwangyingxiao.png"),
        //     text: ["关键词广告投放", "内容运营", "多网站营销"],
        //     imgs: [
        //       require("../assets/img/newmedia/quanwang1.png"),
        //       require("../assets/img/newmedia/quanwang2.png"),
        //     ],
        //   },
        // },
      ],
      navNum: 0, //子nav
      title: "新媒体营销",
      enTitle: "NEW MEDIA MARKETING",
      current: 2,
    }
  },
  mounted() {
    this.navNum = this.$route.query.index?this.$route.query.index:8;
    this.fetchNewMediaList()
  },
  methods: {
    handleCollaborate(){
      this.$router.push({
        path: "/aboutus",
      })
      setTimeout(()=>{
        var el = document.getElementById("lianxi")
        this.$nextTick(function () {
          window.scrollTo({
            behavior: "smooth",
            top: el.offsetTop + -88,
          })
        })
      },100)
    },
    changeNav(id) {
      this.navNum = id
      this.fetchNewMediaList()
    },
    handleShow(index,id) {
      console.log(index)
      this.current = index
      if (index == 0) {
        this.$router.push({
          path: "/",
        })
      }
      if (index == 1) {
        this.$router.push({
          path: "/allline",
          query:{
            index:id
          }
        })
      }
      if (index == 3) {
        this.$router.push({
          path: "/news",
        })
      }
      if (index == 4) {
        this.$router.push({
          path: "/aboutus",
        })
      }
      this.handelUpdate()
      if( id && (id==5 || id==6 || id==7) ){
        setTimeout(()=>{
          var el = document.querySelector(".navs")
          this.$nextTick(function () {
            window.scrollTo({
              behavior: "smooth",
              top: el.offsetTop + -88,
            })
          })
        },100)
      }else if( id && (id!=5 || id!=6 || id!=7) ){
        setTimeout(()=>{
          var el = document.getElementById(id)
          this.$nextTick(function () {
            window.scrollTo({
              behavior: "smooth",
              top: el.offsetTop + -88,
            })
          })
        },100)
      }else{
        this.handelUpdate()
      }
    },
    handelUpdate() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
    },
    scroll({id, offset, num}) {
      if (num == -1) {
        this.navNum = Number(id)
        this.fetchNewMediaList()
        offset = offset || -10
        var el = document.querySelector(".navs")
        this.$nextTick(function () {
          window.scrollTo({
            behavior: "smooth",
            top: el.offsetTop + offset,
          })
        })
      } else {
        this.handleShow(num,id)
      }
    },
    async fetchNewMediaList(){
      let {data} = await newMediaList({
        nav_id:this.navNum
      })
      console.log('newMediaList',data.data);
      this.list = data.data.nav_list;
      this.content = data.data.list;
      if(this.navNum == 0 && this.list.length>0){
        this.navNum = 8
      }
    }
  },
}
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  min-height: 100%;
  padding: 0px;
  margin: 0px;
  background-color: #fff;
  position: relative;
  overflow-x: hidden;
  .navs {
    width: 100vw;
    height: 330px;
    > ul {
      height: 100%;
      padding: 0px 35px;
      > li {
        height: 100%;
        > .line {
          transition: all 0.3s linear;
        }
      }
    }
  }

  .content {
    width: 100vw;
    height: 750px;
    background: url("http://www.ruizhancq.com/common/newmedia/bg.png");
    background-size: 100% 100%;
    > div {
      width: 58%;
      > .left {
        max-width: 520px;
      }
      @media screen and (min-width: 750px) and (max-width: 1650px) {
        > .left {
          max-width: 380px;
          .ft32{
            font-size: 24px;
          }
        }
      }
      > .right {
        > img {
          width: 224px;
          height: 450px;
        }
        @media screen and (min-width: 750px) and (max-width: 1650px) {
          >img{
            width: 174px;
            height: 350px;
          }
        }
      }
    }
    @media screen and (min-width: 1650px) and (max-width: 1900px) {
      > div{
        width: 64%;
      }
    }
    @media screen and (min-width: 1320px) and (max-width: 1650px) {
      > div{
        width: 60%;
      }
    }
    @media screen and (min-width: 1200px) and (max-width: 1320px) {
      > div{
        width: 65%;
        > .left {
          max-width: 340px;
          .ft32{
            font-size: 20px;
          }
        }
      }
    }
    @media screen and (min-width: 1000px) and (max-width: 1200px) {
      > div{
        width: 75%;
        > .left {
          max-width: 300px;
          .ft32{
            font-size: 20px;
          }
        }
      }
    }
    @media screen and (min-width: 750px) and (max-width: 1000px) {
      > div{
        width: 80%;
        > .left {
          max-width: 280px;
          .ft32{
            font-size: 18px;
          }
        }
        >.right{
          >img{
            width: 154px;
            height: 320px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 750px){
    .navs {
      height: 17.1875vw;
      > ul {
        padding: 0px 1.8229vw;
      }
    }
    .content{
      height: 39.0625vw;
      > div {
        width: 80%;
        > .left {
          max-width: 27.0833vw;
          .ft32{
            font-size: 8px;
          }
        }
        >.right{
          > img {
            width: 11.6667vw;
            height: 23.4375vw;
          }
        }
      }
    }
  }
}
</style>
