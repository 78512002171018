<template>
  <div class="main primary_color">
    <!-- nav -->
    <my-nav
      :current="current"
      @onHandleShow="handleShow"
    ></my-nav>

    <!-- 关于我们 -->
    <son-banner :title="title" :enTitle="enTitle" :current="current"></son-banner>

    <!-- 公司简介 -->
    <div id="jieshao" class="introduce column align_center">
      <div class="">
        <div class="ft30 mt100">
          <div class="bolder">公司简介</div>
          <div>Company Introduction</div>
        </div>
        <div class="row mt80">
          <div>
            <div class="bgBox"></div>
            <img :src="content.thumb" alt="">
          </div>
          <div class="column justify_sb">
            <div>
              <div class="ft30">{{ content.title }}</div>
              <div class="ft24 mt20" style="line-height: 36px;">{{ content.description }}</div>
              <ul class="row mt50">
                <li class="row align_center mr55" v-for="(item,index) in content.images" :key="index">
                  <img :src="item" alt="">
                  <!-- <div class="ft16 ml13" style="color: #2E4C89;">创新</div> -->
                </li>
                <!-- <li class="row align_center" style="margin-right: 55px">
                  <img src="../assets/img/aboutus/icon_chuanxin.png" alt="">
                  <div class="ft16 ml13" style="color: #2E4C89;">质量</div>
                </li>
                <li class="row align_center" style="margin-right: 55px">
                  <img src="../assets/img/aboutus/icon_chuanxin.png" alt="">
                  <div class="ft16 ml13" style="color: #2E4C89;">销售</div>
                </li>
                <li class="row align_center" style="margin-right: 55px">
                  <img src="../assets/img/aboutus/icon_chuanxin.png" alt="">
                  <div class="ft16 ml13" style="color: #2E4C89;">服务</div>
                </li> -->
              </ul>
            </div>
            <div class="row align_center">
              <div class="prev cursor" :class="cNum==1?'prevN':''" @click="prevBtn"></div>
              <div class="show_color marginx20">{{ cNum }}/{{ allNum }}</div>
              <div class="next cursor" :class="cNum==allNum?'nextN':''" @click="nextBtn"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 合作咨询 -->
    <div id="lianxi" class="connect column align_center">
      <div class="">
        <div class="ft30 mt100">
          <div class="bolder">合作咨询</div>
          <div>Cooperative Consultation</div>
        </div>
        <div class="row mt80">
          <!-- <div class="column align_center white_color">
            <div
                class="mt56 ft32 bolder"
                data-wow-duration="0.8s"
                data-wow-delay="0.2s"
                data-wow-iteration="1"
              >
                联系我们
              </div>
              <div
                class="line mt20"
                data-wow-duration="0.8s"
                data-wow-delay="0.2s"
                data-wow-iteration="1"
              ></div>
              <div class="mt48 ft20">
                <div class="row">
                  <img src="http://www.ruizhancq.com/common/aboutus/icon_youxiang.png" alt="">
                  <div class="ml18">{{ mail }}</div>
                </div>
                <div class="row mt16">
                  <img src="http://www.ruizhancq.com/common/aboutus/icon_lianxidianhua.png" alt="">
                  <div class="ml18">{{ phone }}4</div>
                </div>
                <div class="row mt16">
                  <img src="http://www.ruizhancq.com/common/aboutus/icon_gongsidizhi.png" alt="">
                  <div class="ml18">{{ address }}</div>
                </div>
              </div>
              <div class="mt60">
                <img :src="ewm" alt="">
              </div>
          </div> -->
          
          <!-- <div>
            <div
              class="formBox bg_w br8 shadow_form column align_center"
            >
              <div
                class="mt56 ft32 bolder"
                data-wow-duration="0.8s"
                data-wow-delay="0.2s"
                data-wow-iteration="1"
              >
                在线咨询
              </div>
              <div
                class="line mt20"
                data-wow-duration="0.8s"
                data-wow-delay="0.2s"
                data-wow-iteration="1"
              ></div>
              <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="0px"
                class="form mt30"
              >
                <div class="row wrap justify_sb ft20">
                  <el-form-item prop="name" class="input">
                    <el-input
                      v-model="ruleForm.name"
                      placeholder="您的称呼"
                      clearable
                    ></el-input>
                  </el-form-item>
                  <el-form-item prop="mobile" class="input">
                    <el-input
                      v-model="ruleForm.mobile"
                      placeholder="联系电话"
                      clearable
                    ></el-input>
                  </el-form-item>
                  <el-form-item prop="qqWeChart" class="input mt50">
                    <el-input
                      v-model="ruleForm.qqWeChart"
                      placeholder="微信或QQ"
                      clearable
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="mt34">
                  <div class="">推广选择</div>
                  <el-form-item prop="radio">
                    <el-radio-group v-model="ruleForm.radio" class="mt50 input_color">
                      <el-radio :label="1">全网营销</el-radio>
                      <el-radio :label="2">新媒体营销</el-radio>
                      <el-radio :label="3">全网营销 + 新媒体营销</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </div>
              </el-form>
              <div class="subBtn mt56 bg_primary white_color ft24 row flex_center cursor" style="margin-bottom: 66px;" @click="to_sub">
                提交
              </div>
            </div>
          </div> -->
          <div
            class="formBox bg_w br8 shadow_form column align_center"
          >
            <div
              class="mt80 ft32 bolder"
              data-wow-duration="0.8s"
              data-wow-delay="0.2s"
              data-wow-iteration="1"
            >
            在线咨询
            </div>
            <div
              class="line mt20"
              data-wow-duration="0.8s"
              data-wow-delay="0.2s"
              data-wow-iteration="1"
            ></div>
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="0px"
              class="form mt67"
            >
              <div class="row wrap justify_sb ft20">
                <el-form-item prop="name" class="input">
                  <el-input
                    v-model="ruleForm.name"
                    placeholder="您的称呼"
                    clearable
                  ></el-input>
                </el-form-item>
                <el-form-item prop="mobile" class="input">
                  <el-input
                    v-model="ruleForm.mobile"
                    placeholder="联系电话"
                    clearable
                  ></el-input>
                </el-form-item>
                <el-form-item prop="qqWeChart" class="input mt50">
                  <el-input
                    v-model="ruleForm.qqWeChart"
                    placeholder="微信或QQ"
                    clearable
                  ></el-input>
                </el-form-item>
              </div>
              <div class="mt50">
                <div class="">推广选择</div>
                <el-form-item prop="radio">
                  <el-radio-group v-model="ruleForm.radio" class="mt50 input_color">
                    <el-radio :label="1">全网营销</el-radio>
                    <el-radio :label="2">新媒体营销</el-radio>
                    <el-radio :label="3">全网营销 + 新媒体营销</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
            </el-form>
            <div
              class="subBtn mt100 bg_primary white_color ft24 row flex_center cursor"
              @click="to_sub"
            >
              提交
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- other -->
    <my-other :current="current"></my-other>

    <!-- footer -->
    <my-footer :current="current" @onScrollTo="scroll"></my-footer>
  </div>
</template>

<script>
import {getAbout, consultSub, otherVal} from "@/api/app"
export default {
  data() {
    return {
      mail:'',
      phone:'',
      address:'',
      content:{},
      list:[],
      ruleForm: {
        name: "",
        mobile: "",
        qqWeChart: "",
        radio: 1,
      },
      rules: {
        name: [{required: true, message: "请输入您的称呼", trigger: "blur"}],
        mobile: [{required: true, message: "请输入联系电话", trigger: "blur"}],
        qqWeChart: [
          {required: true, message: "请输入微信或QQ", trigger: "blur"},
        ],
      },
      allNum:4,
      cNum:1,
      title:'关于我们',
      enTitle:'ABOUT US',
      current: 4,
    }
  },
  mounted() {
    this.fetchAbout()
    this.fetchOtherVal()
  },
  methods: {
    prevBtn(){
      if(this.cNum < 1){
        this.cNum = 1
      }else{
        this.cNum--;
      }
      this.content = this.list[this.cNum - 1]
    },
    nextBtn(){
      if(this.cNum > this.allNum){
        this.cNum = this.allNum
      }else{
        this.cNum++;
      }
      this.content = this.list[this.cNum - 1]
    },
    async to_sub() {
      console.log("表单", this.ruleForm)
      let {data} = await consultSub({
        name: this.ruleForm.name,
        phone: this.ruleForm.mobile,
        wechat: this.ruleForm.qqWeChart,
        type: this.ruleForm.radio,
      })
      if (data.code == 1) {
        this.$message({
          message: "提交成功",
          type: "success",
        })
        this.ruleForm.name = ''
        this.ruleForm.mobile = ''
        this.ruleForm.qqWeChart = ''
        this.ruleForm.radio = 1
      } else {
        this.$message.error(data.msg)
      }
    },
    handleShow(index,id) {
      console.log(id,index)
      this.current = index
      if (index == 0) {
        this.$router.push({
          path: "/",
        })
      }
      if (index == 1) {
        this.$router.push({
          path: "/allline",
          query:{
            index:id
          }
        })
      }
      if (index == 2) {
        this.$router.push({
          path: "/newmedia",
          query:{
            index:id
          }
        })
      }
      if (index == 3) {
        this.$router.push({
          path: "/news",
        })
      }
      this.handelUpdate()
      if(id){
        setTimeout(()=>{
          var el = document.getElementById(id)
          this.$nextTick(function () {
            window.scrollTo({
              behavior: "smooth",
              top: el.offsetTop + -88,
            })
          })
        },100)
      }else{
        return
      }
    },
    handelUpdate() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
    },
    scroll({id, offset, num}) {
      console.log("111")
      if (num == -1) {
        offset = offset || -10
        var el = document.getElementById(id)
        this.$nextTick(function () {
          window.scrollTo({
            behavior: "smooth",
            top: el.offsetTop + offset,
          })
        })
      } else {
        this.handleShow(num,id)
      }
    },
    async fetchAbout(){
      let {data} = await getAbout()
      console.log('getAbout',data.data);
      this.list = data.data.about_list
      this.content = this.list[this.cNum - 1]
      this.allNum = this.list.length;
    },
    async fetchOtherVal() {
      let {data} = await otherVal()
      console.log('otherVal',data.data);
      this.mail = data.data.mail
      this.phone = data.data.phone
      this.address = data.data.address
      this.ewm = data.data.er_img
    },
  },
}
</script>

<style scoped lang="scss">
.main{
  width: 100%;
  min-height: 100%;
  padding: 0px;
  margin: 0px;
  background-color: #fff;
  position: relative;
  overflow-x: hidden;

  .introduce{
    width: 100vw;
    padding-bottom: 100px;
    background-color: #fff;
    >div{
      width: 62.5%;
      height: 100%;
      >div:nth-of-type(2){
        >div:nth-of-type(1){
          width: 38%;
          // height: 550px;
          height: 26vw;
          position: relative;
          >img{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 10;
          }
          >.bgBox{
            width: 100%;
            height: 100%;
            background-color: #5B86DD;
            position: absolute;
            top: 20px;
            left: -20px;
          }
        }
        >div:nth-of-type(2){
          width: 62%;
          padding-left: 54px;
          .prev{
            width: 40px;
            height: 40px;
            background: url('http://www.ruizhancq.com/common/aboutus/icon_zoujiantou_lan.png') no-repeat;
            background-size: 100% 100%;
          }
          .prevN{
            background: url('http://www.ruizhancq.com/common/aboutus/icon_zoujiantou_40.png') no-repeat;
            background-size: 100% 100%;
          }
          .next{
            width: 40px;
            height: 40px;
            background: url('http://www.ruizhancq.com/common/aboutus/icon_youjiantou_40.png') no-repeat;
            background-size: 100% 100%;
          }
          .nextN{
            background: url('http://www.ruizhancq.com/common/aboutus/icon_youjiantou_hui.png') no-repeat;
            background-size: 100% 100%;
          }
          @media screen and (min-width: 1350px) and (max-width: 1500px){
            .prev,.next{
              width: 30px;
              height: 30px;
            }
            .mt50{
              margin-top: 2.6vw;
            }
            .ft30{
              font-size: 24px;
            }
            .ft24{
              font-size: 18px;
              line-height: 30px !important;
            }
            .mr55{
              margin-right: 2.8vw;
            }
            li img{
              width: 5.5vw;
              height: 2.2vw;
            }
          }
          @media screen and (min-width: 1100px) and (max-width: 1350px){
            .prev,.next{
              width: 30px;
              height: 30px;
            }
            .mt50{
              margin-top: 2.6vw;
            }
            .ft30{
              font-size: 22px;
            }
            .ft24{
              font-size: 16px;
              line-height: 28px !important;
            }
            .mr55{
              margin-right: 2.8vw;
            }
            li img{
              width: 5.5vw;
              height: 2.2vw;
            }
          }
          @media screen and (min-width: 750px) and (max-width: 1100px){
            .marginx20{
              margin: 0px 20px;
              font-size: 14px;
            }
            .prev,.next{
              width: 25px;
              height: 25px;
            }
            .mt50{
              margin-top: 2.6vw;
            }
            .ft30{
              font-size: 20px;
            }
            .ft24{
              font-size: 14px;
              line-height: 24px !important;
            }
            .mr55{
              margin-right: 2.8vw;
            }
            li img{
              width: 60px;
              height: 24px;
            }
          }
          @media screen and (max-width: 750px){
            .marginx20{
              margin: 5px 20px 0;
              font-size: 6px;
              line-height: 10px;
            }
            .prev,.next{
              width: 10px;
              height: 10px;
            }
            .mt50{
              margin-top: 2.6vw;
            }
            .ft30{
              font-size: 8px;
            }
            .ft24{
              font-size: 6px;
              line-height: 10px !important;
            }
            .mr55{
              margin-right: 2.8vw;
            }
            li img{
              width: 5.5vw;
              height: 2.2vw;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 750px){
    .introduce{
      padding-bottom: 5.2083vw;
      >div{
        >div:nth-of-type(2){
          >div:nth-of-type(1){
            height: 26vw;
            >.bgBox{
              top: 1.0417vw;
              left: -1.0417vw;
            }
          }
          >div:nth-of-type(2){
            padding-left: 2.8125vw;
            .prev{
              width: 10px;
              height: 10px;
            }
            .next{
              width: 10px;
              height: 10px;
            }
            .ft30{
              font-size: 8px;
            }
            .ft24{
              font-size: 6px;
              line-height: 10px !important;
            }
          }
        }
      }
    }
  }    

  .connect{
    width: 100vw;
    padding-bottom: 100px;
    background-color: #F4F6F9;
    >div{
      width: 62.5%;
      height: 100%;
      >div:nth-of-type(2){
        .formBox {
          width: 1200px;
          height: 800px;
          padding: 0px 60px;
          .form {
            width: 1080px;
            .input {
              width: 520px;
              height: 56px;
            }
          }
          .subBtn {
            width: 400px;
            height: 60px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 750px){
    .connect{
      padding-bottom: 5.2083vw;
      >div{
        >div:nth-of-type(2){
          .formBox {
            width: 62.5vw;
            height: 48vw;
            padding: 0px 3.125vw;
            .form {
              width: 56.25vw;
              height: 24vw;
              .input {
                width: 27.0833vw;
                height: 2.9167vw;
                margin-bottom: 4px;
              }
            }
            .subBtn {
              width: 20.8333vw;
              height: 3.125vw;
            }
          }
        }
      }
    }
  }
}
::v-deep .el-radio__label {
  font-size: 20px;
}

::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #4581fb;
}

::v-deep .el-radio__inner {
  width: 20px;
  height: 20px;
  border: 1px solid #c9c8c8;
}

::v-deep .el-radio__inner:hover {
  border: 1px solid #4581fb;
}

::v-deep .el-radio__inner::after {
  width: 8px;
  height: 8px;
  background-color: #4581fb;
}

::v-deep .el-radio__input.is-checked .el-radio__inner {
  background-color: #ffffff;
  background: #ffffff;
  border: 2px solid #4581fb;
  border-color: #4581fb;
}

::v-deep .el-input__inner.el-input__inner.el-input__inner {
  height: 56px;
  padding-left: 19px;
  background-color: #f5f5f5;
  border: 1px solid #e9e9e9;
  font-size: 20px;
}

::v-deep .el-carousel__indicator--horizontal.el-carousel__indicator--horizontal.el-carousel__indicator--horizontal {
  padding: 20px 5px !important;
}

::v-deep .el-carousel__indicator--horizontal .el-carousel__button.el-carousel__button {
  width: 40px !important;
  height: 5px !important;
}
@media screen and (max-width: 750px) {
  ::v-deep .el-radio__label {
    font-size: 6px;
  }

  ::v-deep .el-radio__inner {
    width: 1.0417vw;
    height: 1.0417vw;
  }

  ::v-deep .el-radio__inner::after {
    width: 0.4167vw;
    height: 0.4167vw;
  }

  ::v-deep .el-radio__input.is-checked .el-radio__inner {
    border: 1px solid #4581fb;
    border-color: #4581fb;
  }

  ::v-deep .el-input__inner.el-input__inner.el-input__inner {
    height: 2.9167vw;
    padding-left: 0.9896vw;
    font-size: 6px;
  }
  ::v-deep .el-form-item__content{
    line-height: 10px !important;
  }
}
</style>
