<template>
  <div class="main primary_color">
    <!-- nav -->
    <my-nav
      :current="current"
      @onHandleShow="handleShow"
    ></my-nav>

    <!-- 全网营销 -->
    <son-banner :title="title" :enTitle="enTitle" :current="current"></son-banner>

    <!-- 子nav -->
    <ul class="navs ml360 mr360 row justify_sb">
      <li
        class="navLi bg_w br4 column flex_center cursor"
        v-for="(item) in navLi"
        :key="item.id"
        :class="navNum == item.id ? 'navshow' : ''"
        @click="changeNav(item.id)"
      >
        <div class="ft30 bolder">{{ item.catname }}</div>
        <!-- <div
          class="ft20 text_center mt25"
          v-if="navNum == item.id"
          style="line-height: 32px"
        >
          {{ item.text }}
        </div> -->
      </li>
    </ul>

    <!-- 推广优势 -->
    <div class="advantageBox1 column align_center" v-if="navNum == 0 || navNum == 5">
      <div class="row flex_center ft36 bolder mt100">{{ navText }}</div>
      <div class="mt60 ft32 bolder">推广优势</div>
      <div class="line mt20"></div>
      <div class="advantage mt60 row flex_center">
        <ul class="row ml360 mr360 white_color">
          <li class="column align_center justify_sb wow animate__animated animate__zoomIn" data-wow-duration="0.8s" :data-wow-delay="index * 0.2 + 's'" data-wow-iteration="1" v-for="(item,index) in val_list" :key="item.id">
            <img :src="item.thumb" alt="" />
            <div class="ft26 mt8">{{ item.title }}</div>
            <div class="ft20 text_center lh32">
              {{item.description}}
            </div>
            <div class="line mt48" style="background: #ffffff"></div>
          </li>
          <!-- <li class="column align_center justify_sb wow animate__animated animate__zoomIn" data-wow-duration="0.8s" data-wow-delay="0.2s" data-wow-iteration="1">
            <img src="../assets/img/allline/icon_jingzhun.png" alt="" />
            <div class="ft26 mt8">精准</div>
            <div class="ft20 text_center" style="line-height: 32px">
              精准获取客户，定向展现营销内容
            </div>
            <div class="line mt48" style="background: #ffffff"></div>
          </li>
          <li class="column align_center justify_sb wow animate__animated animate__zoomIn" data-wow-duration="0.8s" data-wow-delay="0.4s" data-wow-iteration="1">
            <img src="../assets/img/allline/icon_quanmian.png" alt="" />
            <div class="ft26 mt8">全面</div>
            <div class="ft20 text_center" style="line-height: 32px">
              拥有海量优质媒体资源，用户触达全网
            </div>
            <div class="line mt48" style="background: #ffffff"></div>
          </li>
          <li class="column align_center justify_sb wow animate__animated animate__zoomIn" data-wow-duration="0.8s" data-wow-delay="0.6s" data-wow-iteration="1">
            <img src="../assets/img/allline/icon_youxiao.png" alt="" />
            <div class="ft26 mt8">有效</div>
            <div class="ft20 text_center" style="line-height: 32px">
              引流至线下促进成交
            </div>
            <div class="line mt48" style="background: #ffffff"></div>
          </li> -->
        </ul>
      </div>
    </div>
    <div class="advantageBox2 column align_center" v-else-if="navNum == 6">
      <div class="row flex_center ft36 bolder mt100">{{ navText }}</div>
      <div class="mt60 ft32 bolder">推广优势</div>
      <div class="line mt20"></div>
      <div class="advantage mt60">
        <ul class="row white_color wrap ft30">
          <li v-for="(item,index) in val_list" :key="item.id">
            <img :src="item.images[0]" alt="">
            <div class="textBox row align_center wow animate__animated animate__fadeInUp" data-wow-duration="0.6s" :data-wow-delay="index > 1? '0.4s' :'0s'" data-wow-iteration="1">
              <img class="wh40" :src="item.thumb" alt="">
              <div class="ml30 ft32">{{ item.title }}</div>
            </div>
          </li>
          <!-- <li>
            <img src="../assets/img/allline/Mask group(2).png" alt="">
            <div class="textBox row align_center wow animate__animated animate__fadeInUp" data-wow-duration="0.6s" data-wow-delay="0s" data-wow-iteration="1">
              <img class="wh40" src="../assets/img/allline/icon_gaoxiao.png" alt="">
              <div class="ml30">高效留客提高转化率</div>
            </div>
          </li>
          <li>
            <img src="../assets/img/allline/Mask group(1).png" alt="">
            <div class="textBox row align_center wow animate__animated animate__fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.4s" data-wow-iteration="1">
              <img class="wh40" src="../assets/img/allline/icon_youzhineirong.png" alt="">
              <div class="ml30">拥有海量优质内容，吸引全网用户</div>
            </div>
          </li>
          <li>
            <img src="../assets/img/allline/Mask group(3).png" alt="">
            <div class="textBox row align_center wow animate__animated animate__fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.4s" data-wow-iteration="1">
              <img class="wh40" src="../assets/img/allline/icon_chengjiao.png" alt="">
              <div class="ml30">引流至线下促进成交</div>
            </div>
          </li> -->
        </ul>
      </div>
    </div>
    <div class="advantageBox3 column align_center" v-else>
      <div class="row flex_center ft36 bolder mt100">{{ navText }}</div>
      <div class="mt60 ft32 bolder">推广优势</div>
      <div class="line mt20"></div>
      <div class="advantage mt60">
        <ul class="row white_color wrap">
          <li class="wow animate__animated animate__fadeInRight" data-wow-duration="0.6s" :data-wow-delay="index % 2 == 0?'0.3s':'0s'" data-wow-iteration="1" v-for="(item,index) in val_list" :key="item.id">
            <img :src="item.images[0]" alt="">
            <div class="textBox row align_center">
              <img :src="item.thumb" alt="" class="wh56">
              <div class="ml30">
                <div class="ft30">{{ item.title }}</div>
                <div class="ft24 mt30">{{item.title}}</div>
              </div>
            </div>
          </li>
          <!-- <li class="wow animate__animated animate__fadeInRight" data-wow-duration="0.6s" data-wow-delay="0s" data-wow-iteration="1">
            <img src="../assets/img/allline/Mask group2.png" alt="">
            <div class="textBox row align_center">
              <img src="../assets/img/allline/pinpaichayi.png" alt="" style="width: 56px;height: 56px;">
              <div class="ml30">
                <div class="ft30">品牌差异</div>
                <div class="ft24 mt30">树立品牌差异性，建设品牌粉丝经济</div>
              </div>
            </div>
          </li>
          <li class="wow animate__animated animate__fadeInRight" data-wow-duration="0.6s" data-wow-delay="0.3s" data-wow-iteration="1">
            <img src="../assets/img/allline/Mask group3.png" alt="">
            <div class="textBox row align_center">
              <img src="../assets/img/allline/pinpairentong.png" alt="" style="width: 56px;height: 56px;">
              <div class="ml30">
                <div class="ft30">品牌认同</div>
                <div class="ft24 mt30">提升品牌认同感，积累品牌忠实用户</div>
              </div>
            </div>
          </li>
          <li class="wow animate__animated animate__fadeInRight" data-wow-duration="0.6s" data-wow-delay="0s" data-wow-iteration="1">
            <img src="../assets/img/allline/Mask group4.png" alt="">
            <div class="textBox row align_center">
              <img src="../assets/img/allline/icon_chengjiao.png" alt="" style="width: 56px;height: 56px;">
              <div class="ml30">
                <div class="ft30">成交</div>
                <div class="ft24 mt30">引流至线下促进成交</div>
              </div>
            </div>
          </li> -->
        </ul>
      </div>
    </div>

    <!-- 展现形式 -->
    <div class="showBox column align_center">
      <div class="mt100 ft32 bolder wow animate__animated animate__fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s" data-wow-iteration="1">展现形式</div>
      <div class="line mt20 wow animate__animated animate__fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s" data-wow-iteration="1"></div>
      <div class="show1" v-if="navNum == 0 || navNum == 5">
        <div class="mt60 row ft24 ml360 mr360">
          <div class="num1 row flex_center cursor" :class="showNum1==0?'showNum1':''" @click="changeShow1(0)">各大网站平台信息推广</div>
          <div class="num1 row flex_center cursor" :class="showNum1==1?'showNum1':''" @click="changeShow1(1)">关键词搜索霸屏式推广</div>
        </div>
        <!-- <div class="column align_center" style="width: 100%;padding-top: 40px;">
          <img class="" :src="showImg" alt="" style="width: 80%;">
        </div> -->
        <div class="box1 mt60" :style="{backgroundImage: 'url('+ img_five +')',backgroundSize: '100% 100%'}" v-show="showNum1 == 0">
          <img class="img1 ml360 wow animate__animated animate__zoomIn" v-if="val_two_list_one[0].thumb[0]" :src="val_two_list_one[0].thumb[0]" alt="" data-wow-duration="0.8s" data-wow-delay="0.2s" data-wow-iteration="1">
          <img class="img2 wow animate__animated animate__zoomIn" v-if="val_two_list_one[0].thumb[1]" :src="val_two_list_one[0].thumb[1]" alt="" data-wow-duration="0.8s" data-wow-delay="0.4s" data-wow-iteration="1">
          <img class="img3 mr360 wow animate__animated animate__zoomIn" v-if="val_two_list_one[0].thumb[2]" :src="val_two_list_one[0].thumb[2]" alt="" data-wow-duration="0.8s" data-wow-delay="0.6s" data-wow-iteration="1">
        </div>
        <div class="box2 mt60" :style="{backgroundImage: 'url('+ img_five +')',backgroundSize: '100% 100%'}" v-show="showNum1 != 0">
          <img class="img1" v-if="val_two_list_two[0].thumb[0]" :src="val_two_list_two[0].thumb[0]" alt="">
          <img class="img2 wow animate__animated animate__zoomIn" v-if="val_two_list_two[0].thumb[1]" :src="val_two_list_two[0].thumb[1]" alt="" data-wow-duration="0.8s" data-wow-delay="0.2s" data-wow-iteration="1">
        </div>
      </div>
      <div class="show2" v-else-if="navNum == 6">
        <div class="mt60 row ft24 ml360 mr360">
          <div class="num1 row flex_center cursor" :class="showNum2==0?'showNum1':''" @click="changeShow2(0)">自媒体平台推广发布</div>
          <div class="num1 row flex_center cursor" :class="showNum2==1?'showNum1':''" @click="changeShow2(1)">各大网站发稿</div>
        </div>
        <!-- <div class="column align_center" style="width: 100%;padding-top: 40px;">
          <img class="" :src="showImg" alt="" style="width: 80%;">
        </div> -->
        <div class="box1 mt60 row justify_sa align_center" :style="{backgroundImage: 'url('+ img_five +')',backgroundSize: '100% 100%'}" v-show="showNum2 == 0">
          <img class="img wow animate__animated animate__fadeInUp" v-if="val_two_list_one[0].thumb[0]" :src="val_two_list_one[0].thumb[0]" alt="" data-wow-duration="0.8s" data-wow-delay="0.2s" data-wow-iteration="1">
          <img class="img wow animate__animated animate__fadeInUp" v-if="val_two_list_one[0].thumb[1]" :src="val_two_list_one[0].thumb[1]" alt="" data-wow-duration="0.8s" data-wow-delay="0.4s" data-wow-iteration="1">
          <img class="img wow animate__animated animate__fadeInUp" v-if="val_two_list_one[0].thumb[2]" :src="val_two_list_one[0].thumb[2]" alt="" data-wow-duration="0.8s" data-wow-delay="0.6s" data-wow-iteration="1">
        </div>
        <div class="box2 mt60" :style="{backgroundImage: 'url('+ img_five +')',backgroundSize: '100% 100%'}" v-show="showNum2 != 0">
          <img class="img1 ml360 wow animate__animated animate__zoomIn" v-if="val_two_list_two[0].thumb[0]" :src="val_two_list_two[0].thumb[0]" alt="" data-wow-duration="0.8s" data-wow-delay="0.2s" data-wow-iteration="1">
          <img class="img2 wow animate__animated animate__zoomIn" v-if="val_two_list_two[0].thumb[1]" :src="val_two_list_two[0].thumb[1]" alt="" data-wow-duration="0.8s" data-wow-delay="0.4s" data-wow-iteration="1">
          <img class="img3 mr360 wow animate__animated animate__zoomIn" v-if="val_two_list_two[0].thumb[2]" :src="val_two_list_two[0].thumb[2]" alt="" data-wow-duration="0.8s" data-wow-delay="0.6s" data-wow-iteration="1">
        </div>
      </div>
      <div class="show3" v-else>
        <div class="mt60 row ft24 ml360 mr360">
          <div class="num1 row flex_center cursor" :class="showNum3==0?'showNum1':''" @click="changeShow3(0)">多渠道多平台布局曝光</div>
          <div class="num1 row flex_center cursor" :class="showNum3==1?'showNum1':''" @click="changeShow3(1)">小程序推广活动界面展示</div>
        </div>
        <!-- <div class="column align_center" style="width: 100%;padding-top: 40px;">
          <img class="" :src="showImg" alt="" style="width: 80%;">
        </div> -->
        <div class="box1 mt60" :style="{backgroundImage: 'url('+ img_five +')',backgroundSize: '100% 100%'}" v-show="showNum3 == 0">
          <img class="img1" v-if="val_two_list_one[0].thumb[0]" :src="val_two_list_one[0].thumb[0]" alt="">
          <img class="img2 wow animate__animated animate__zoomIn" v-if="val_two_list_one[0].thumb[1]" :src="val_two_list_one[0].thumb[1]" alt="" data-wow-duration="0.8s" data-wow-delay="0.2s" data-wow-iteration="1">
        </div>
        <div class="box2 mt60 row justify_sa align_center" :style="{backgroundImage: 'url('+ img_five +')',backgroundSize: '100% 100%'}" v-show="showNum3 != 0">
          <img class="img wow animate__animated animate__fadeInUp" v-if="val_two_list_two[0].thumb[0]" :src="val_two_list_two[0].thumb[0]" alt="" data-wow-duration="0.8s" data-wow-delay="0.2s" data-wow-iteration="1">
          <img class="img wow animate__animated animate__fadeInUp" v-if="val_two_list_two[0].thumb[1]" :src="val_two_list_two[0].thumb[1]" alt="" data-wow-duration="0.8s" data-wow-delay="0.4s" data-wow-iteration="1">
          <img class="img wow animate__animated animate__fadeInUp" v-if="val_two_list_two[0].thumb[2]" :src="val_two_list_two[0].thumb[2]" alt="" data-wow-duration="0.8s" data-wow-delay="0.6s" data-wow-iteration="1">
        </div>
      </div>
    </div>

    <!-- 推广案例 -->
    <div class="caseBox column align_center">
      <div class="mt100 ft32 bolder wow animate__animated animate__fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s" data-wow-iteration="1">推广案例</div>
      <div class="line mt20 wow animate__animated animate__fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s" data-wow-iteration="1"></div>
      <!-- <div class="case row flex_center" style="width: 100%;">
        <img class="" :src="caseImg" alt="" style="width: 62.5%;">
      </div> -->
      <div class="case row flex_center mt60" :style="{backgroundImage: 'url('+ tuiguang_img +')',backgroundSize: '100% 100%'}">
        <div class="case1 row justify_sb align_center">
          <div class="column align_center">
            <img class="img1 mt20" :src="val_three_list[0].thumb[0]" alt="">
            <img class="img2 wow animate__animated animate__zoomIn" :src="val_three_list[0].thumb[2]" alt="" >
          </div>
          <div class="column align_center">
            <img class="img1 mt20" :src="val_three_list[0].thumb[1]" alt="">
            <img class="img2 wow animate__animated animate__zoomIn" :src="val_three_list[0].thumb[3]" alt="" >
          </div>
        </div>
        <!-- <div class="case1 row justify_sb align_center" v-if="navNum == 5">
          <div class="column align_center">
            <img class="img1 mt20" :src="val_three_list[0].thumb[0]" alt="">
            <img class="img2 wow animate__animated animate__zoomIn" :src="val_three_list[0].thumb[2]" alt="" >
          </div>
          <div class="column align_center">
            <img class="img1 mt20" :src="val_three_list[0].thumb[1]" alt="">
            <img class="img2 wow animate__animated animate__zoomIn" :src="val_three_list[0].thumb[3]" alt="" >
          </div>
        </div>
        <div class="case1 row justify_sb align_center" v-else-if="navNum == 6">
          <div class="column align_center">
            <img class="img1 mt20" :src="val_three_list[0].thumb[0]" alt="">
            <img class="img2 wow animate__animated animate__zoomIn" :src="val_three_list[0].thumb[2]" alt="" >
          </div>
          <div class="column align_center">
            <img class="img1 mt20" :src="val_three_list[0].thumb[1]" alt="">
            <img class="img2 wow animate__animated animate__zoomIn" :src="val_three_list[0].thumb[3]" alt="" >
          </div>
        </div>
        <div class="case1 row justify_sb align_center" v-else>
          <div class="column align_center">
            <img class="img1 mt20" :src="val_three_list[0].thumb[0]" alt="">
            <img class="img2 wow animate__animated animate__zoomIn" :src="val_three_list[0].thumb[2]" alt="" >
          </div>
          <div class="column align_center">
            <img class="img1 mt20" :src="val_three_list[0].thumb[1]" alt="">
            <img class="img2 wow animate__animated animate__zoomIn" :src="val_three_list[0].thumb[3]" alt="" >
          </div>
        </div> -->
      </div>
    </div>

    <!-- other -->
    <my-other :current="current"></my-other>

    <!-- footer -->
    <my-footer :current="current" @onScrollTo="scroll"></my-footer>
  </div>
</template>

<script>
import { networkList, otherVal } from '@/api/app'
export default {
  data() {
    return {
      val_three_list:[],
      tuiguang_img:'',
      img_five:'',
      // caseImg:'',
      // showImg:'',
      val_two_list_two:[],
      val_two_list_one:[],
      val_list:[],
      showNum3: 0,//展现形式2 子nav=2
      showNum2: 0,//展现形式1 子nav=1
      showNum1: 0,//展现形式0 子nav=0
      navNum: 0,//子nav
      navLi: [
        // {
        //   title: "信息流推广",
        //   text: "将您的推广信息自然融入各类资讯、信息中",
        // },
        // {
        //   title: "软文类推广",
        //   text: "将您的推广信息自然融入各类资讯、信息中",
        // },
        // {
        //   title: "信息流推广",
        //   text: "将您的推广信息自然融入各类资讯、信息中",
        // },
      ],
      navText:'',
      title: "全网营销",
      enTitle: "NETWOER-WIDE MARKETING",
      current: 1,
    }
  },
  mounted() {
    this.navNum = this.$route.query.index?this.$route.query.index:5;
    this.fetchNetworkList()
    this.fetchOtherVal()
  },
  methods: {
    changeShow3(tm){
      this.showNum3 = tm;
    },
    changeShow2(tm){
      this.showNum2 = tm;
    },
    changeShow1(tm){
      this.showNum1 = tm;
    },
    changeNav(index) {
      this.navNum = index
      this.fetchNetworkList()
    },
    handleShow(index,id) {
      this.current = index
      if (index == 0) {
        this.$router.push({
          path: "/",
        })
      }
      if (index == 2) {
        this.$router.push({
          path: "/newmedia",
          query:{
            index:id
          }
        })
      }
      if (index == 3) {
        this.$router.push({
          path: "/news",
        })
      }
      if (index == 4) {
        this.$router.push({
          path: "/aboutus",
        })
      }
      this.handelUpdate()
      if( id && (id==8 || id==9 || id==10 || id==11 || id==12 || id==13) ){
        setTimeout(()=>{
          var el = document.querySelector(".navs")
          this.$nextTick(function () {
            window.scrollTo({
              behavior: "smooth",
              top: el.offsetTop + -88,
            })
          })
        },100)
      }else if( id && (id!=8 || id!=9 || id!=10 || id!=11 || id!=12 || id!=13) ){
        setTimeout(()=>{
          var el = document.getElementById(id)
          this.$nextTick(function () {
            window.scrollTo({
              behavior: "smooth",
              top: el.offsetTop + -88,
            })
          })
        },100)
      }else{
        this.handelUpdate()
      }
    },
    handelUpdate() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
    },
    scroll({id, offset, num}) {
      if (num == -1) {
        this.navNum = Number(id)
        this.fetchNetworkList()
        offset = offset || -10
        var el = document.querySelector(".navs")
        this.$nextTick(function () {
          window.scrollTo({
            behavior: "smooth",
            top: el.offsetTop + -88,
          })
        })
      } else {
        this.handleShow(num,id)
      }
    },
    async fetchNetworkList(){
      let {data} = await networkList({
        nav_id:this.navNum
      })
      console.log('networkList',data.data);
      this.navLi = data.data.two_cat

      if(this.navNum == 0){
        this.navNum = this.navLi?this.navLi[0].id:8
        this.navText = this.navLi?this.navLi[0].description:''
      }else{
        this.navLi.forEach(item =>{
          if(item.id == this.navNum){
            this.navText = item.description
          }
        })
      }

      this.val_list = data.data.val_list

      this.val_two_list_one = data.data.val_two_list_one
      this.val_two_list_two = data.data.val_two_list_two

      this.val_three_list = data.data.val_three_list

      // if(this.showImg == ''){
      //   this.showImg = data.data.val_two_list_one[0].thumb
      // }else{
      //   if(this.showNum1 == 0){
      //     this.showImg = data.data.val_two_list_one[0].thumb
      //   }else{
      //     this.showImg = data.data.val_two_list_one[0].thumb
      //   }
      // }
      // this.caseImg = data.data.val_three_list[0].thumb
    },
    async fetchOtherVal() {
      let {data} = await otherVal()
      this.img_five = data.data.img_five
      if(this.navNum == 0 || this.navNum==5){
        this.tuiguang_img = data.data.img_six
      }else if(this.navNum == 6){
        this.tuiguang_img = data.data.img_seven
      }else{
        this.tuiguang_img = data.data.img_eight
      }
    },
  },
}
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  min-height: 100%;
  padding: 0px;
  margin: 0px;
  background-color: #fff;
  position: relative;
  overflow-x: hidden;
  .navs {
    margin-top: -100px;
    .navLi {
      width: calc((100% - 40px) / 3);
      height: 200px;
      padding: 34px;
      box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
      transition: all 0.3s linear;
    }
    .navshow {
      color: #fff;
      background: #5b86dd;
      box-shadow: 0px 3px 10px 0px rgba(69, 129, 251, 0.5);
      transition: all 0.3s linear;
    }
  }
  @media screen and (max-width: 750px){
    .navs {
      margin-top: -5.2083vw;
      .navLi {
        width: calc((100% - 2.0833vw) / 3);
        height: 10.4167vw;
        padding: 1.7708vw;
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
      }
      .navshow {
        box-shadow: 0px 1px 5px 0px rgba(69, 129, 251, 0.5);
      }
    }
  }
  .advantageBox1 {
    width: 100vw;
    .advantage {
      width: 100vw;
      height: calc(100vw / 2.56);
      padding: 0 460px;
      background: url("http://www.ruizhancq.com/common/allline/tuiguangyoushi.png") no-repeat;
      background-size: 100% 100%;
      ul {
        li {
          width: 300px;
          height: 334px;
          padding: 40px 40px 0px;
          background: #2e4c89;
          .lh32{line-height: 32px;}
          > img {
            width: 90px;
            height: 90px;
          }
        }
        @media screen and (min-width: 750px) and (max-width: 1500px) {
          > li {
            width: 220px;
            height: 274px;
            padding: 20px 20px 0px;
            > img {
              width: 60px;
              height: 60px;
            }
            > .line {
              margin-top: 20px;
              width: 50px;
            }
          }
        }
        li:nth-of-type(2n) {
          background: rgba(46, 76, 137, 0.5);
        }
      }
    }
    @media screen and (min-width: 1800px) and (max-width: 2000px) {
      .advantage {
        padding: 0px 360px;
      }
    }
    @media screen and (min-width: 1650px) and (max-width: 1800px) {
      .advantage {
        padding: 0px 200px;
      }
    }
    @media screen and (min-width: 750px) and (max-width: 1650px) {
      .advantage {
        padding: 0px 100px;
        .ft26{
          font-size: 22px;
        }
        .ft20{
          font-size: 16px;
        }
      }
    }
    @media screen and (max-width: 750px){
      .advantage {
        height: calc(100vw / 2.56);
        padding: 0 8.75vw;
        ul {
          li {
            width: 25%;
            height: 25.3958vw;
            padding: 2.0833vw 2.0833vw 0px;
            .lh32{line-height: 10px;}
            > img {
              width: 4.6875vw;
              height: 4.6875vw;
            }
          }
        }
        .ft26{
          font-size: 8px;
        }
        .ft20{
          font-size: 6px;
        }
      }
    }
  }
  .advantageBox2 {
    width: 100vw;
    // height: 1300px;
    margin-top: -100px;
    padding-top: 100px;
    padding-bottom: 150px;
    background: #f4f6f9;
    .advantage {
      width: 100vw;
      padding: 0 460px;
      >ul{
        width: 100%;
        >li{
          width: calc(50% - 10px);
          height: 400px;
          position: relative;
          >img{
            width: 100%;
            height: 100%;
          }
          >.textBox{
            width: 100%;
            height: 111px;
            padding: 35px 30px;
            background: rgba(17,31,67,0.5);
            position: absolute;
            bottom: 0px;
            left: 0px;
          }
        }
        >li:nth-of-type(2n+1){
          margin-right: 20px;
        }
        >li:nth-of-type(3),li:nth-of-type(4){
          margin-top: 20px;
        }
      }
    }
    @media screen and (min-width: 1800px) and (max-width: 2000px) {
      .advantage {
        padding: 0px 360px;
      }
    }
    @media screen and (min-width: 1650px) and (max-width: 1800px) {
      .advantage {
        padding: 0px 200px;
      }
    }
    @media screen and (min-width: 750px) and (max-width: 1650px) {
      .advantage {
        padding: 0px 100px;
      }
    }
  }
  @media screen and (max-width: 750px){
    .advantageBox2 {
      margin-top: -5.2083vw;
      padding-top: 5.2083vw;
      padding-bottom: 7.8125vw;
      .advantage {
        padding: 0 18.75vw;
        >ul{
          >li{
            width: calc(50% - 5.2vw);
            height: 20.8333vw;
            position: relative;
            >.textBox{
              height: 5.78125vw;
              padding: 1.8229vw 1.5625vw;
              .ft32{font-size: 6px;}
            }
          }
          >li:nth-of-type(2n+1){
            margin-right: 1.0417vw;
          }
          >li:nth-of-type(3),li:nth-of-type(4){
            margin-top: 1.0417vw;
          }
        }
      }
    }
  }
  .advantageBox3 {
    width: 100vw;
    // height: 950px;
    margin-top: -100px;
    padding-top: 100px;
    padding-bottom: 160px;
    background: #f4f6f9;
    .advantage {
      width: 100vw;
      padding: 0 460px;
      >ul{
        width: 100%;
        >li{
          width: calc(50% - 10px);
          height: 220px;
          position: relative;
          >img{
            width: 100%;
            height: 100%;
          }
          >.textBox{
            width: 100%;
            height: inherit;
            padding: 52px 34px;
            position: absolute;
            top: 0px;
            left: 0px;
            .wh56{
              width: 56px;
              height: 56px;
            }
          }
          @media screen and (min-width: 750px) and (max-width: 1400px) {
            >.textBox{
              >div:first-of-type{
                >div:first-of-type{
                  font-size: 24px;
                }
                >div:last-of-type{
                  font-size: 18px;
                }
              }
            }
          }
        }
        >li:nth-of-type(2n+1){
          margin-right: 20px;
        }
        >li:nth-of-type(3),li:nth-of-type(4){
          margin-top: 20px;
        }
      }
    }
    @media screen and (min-width: 1800px) and (max-width: 2000px) {
      .advantage {
        padding: 0px 360px;
      }
    }
    @media screen and (min-width: 1650px) and (max-width: 1800px) {
      .advantage {
        padding: 0px 200px;
      }
    }
    @media screen and (max-width: 1650px) {
      .advantage {
        padding: 0px 100px;
      }
    }
  }
  @media screen and (max-width: 750px){
    .advantageBox3 {
      margin-top: -5.2083vw;
      padding-top: 5.2083vw;
      padding-bottom: 8.3333vw;
      .advantage {
        padding: 0 18.75vw;
        >ul{
          >li{
            width: calc(50% - 5.2vw);
            height: 11.4583vw;
            >.textBox{
              padding: 1.8229vw 1.5625vw;
              .wh56{
                width: 2.9167vw;
                height: 2.9167vw;
              }
              >div:first-of-type{
                >div:first-of-type{
                  font-size: 8px;
                }
                >div:last-of-type{
                  font-size: 6px;
                }
              }
            }
          }
          >li:nth-of-type(2n+1){
            margin-right: 1.0417vw;
          }
          >li:nth-of-type(3),li:nth-of-type(4){
            margin-top: 1.0417vw;
          }
        }
      }
    }
  }

  .showBox{
    width: 100vw;
    .show1{
      width: 100vw;
      .num1{
        width: 50%;
        height: 109px;
        background: #FFFFFF;
        border: 1px solid #5B86DD;
        color: #5B86DD;
      }
      .showNum1{
        height: 119px;
        padding-bottom: 10px;
        border: none;
        background: url('http://www.ruizhancq.com/common/allline/panel_xuanzhong.png') no-repeat;
        background-size: 100% 100%;
        color: #fff;
      }

      .box1{
        width: 100vw;
        height: 950px;
        // background: url('../assets/img/allline/zhanxianxingshi.png') no-repeat;
        // background-size: 100% 100%;
        position: relative;
        >.img1{
          width: 560px;
          height: 400px;
          position: absolute;
          top: 90px;
          left: 0px;
          z-index: 10;
        }
        >.img2{
          width: 765px;
          height: 460px;
          position: absolute;
          bottom: 90px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 20;
        }
        >.img3{
          width: 560px;
          height: 400px;
          position: absolute;
          top: 150px;
          right: 0px;
          z-index: 30;
        }
      }
      @media screen and (min-width: 1500px) and (max-width: 2200px) {
        .box1 {
          height: 800px;
          >.img1{
            width: 500px;
            height: 360px;
            position: absolute;
            top: 90px;
            left: 0px;
            z-index: 10;
          }
          >.img2{
            width: 705px;
            height: 420px;
            position: absolute;
            bottom: 90px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 20;
          }
          >.img3{
            width: 500px;
            height: 360px;
            position: absolute;
            top: 150px;
            right: 0px;
            z-index: 30;
          }
        }
      }
      @media screen and (min-width: 750px) and (max-width: 1500px) {
        .box1 {
          height: 700px;
          >.img1{
            width: 450px;
            height: 310px;
            position: absolute;
            top: 90px;
            left: 0px;
            z-index: 10;
          }
          >.img2{
            width: 655px;
            height: 370px;
            position: absolute;
            bottom: 90px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 20;
          }
          >.img3{
            width: 450px;
            height: 310px;
            position: absolute;
            top: 150px;
            right: 0px;
            z-index: 30;
          }
        }
      }

      .box2{
        width: 100vw;
        height: 800px;
        // background: url('../assets/img/allline/zhanxianxingshi.png') no-repeat;
        // background-size: 100% 100%;
        position: relative;
        >.img1{
          width: 1000px;
          height: 595px;
          position: absolute;
          top: 120px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 10;
        }
        >.img2{
          width: 1144px;
          height: 318px;
          position: absolute;
          top: 200px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 20;
        }
      }
      @media screen and (min-width: 750px) and (max-width: 1500px) {
        .box2 {
          height: 700px;
          >.img1{
            width: 800px;
            height: 495px;
            position: absolute;
            top: 120px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 10;
          }
          >.img2{
            width: 944px;
            height: 218px;
            position: absolute;
            top: 180px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 20;
          }
        }
      }
      @media screen and (max-width: 750px){
        .num1{
          height: 5.6771vw;
        }
        .showNum1{
          height: 6.1979vw;
          padding-bottom: 0.5208vw;
        }

        .box1 {
          height: 41.6667vw;
          >.img1{
            width: 32vw;
            height: 22vw;
            top: 4.6875vw;
          }
          >.img2{
            width: 42vw;
            height: 26vw;
            bottom: 4.6875vw;
          }
          >.img3{
            width: 32vw;
            height: 22vw;
            top: 7.8125vw;
          }
        }
        .box2 {
          height: 36.4583vw;
          >.img1{
            width: 41.6667vw;
            height: 25.78125vw;
            top: 6.25vw;
          }
          >.img2{
            width: 49.1667vw;
            height: 11.3542vw;
            top: 9.375vw;
          }
        }
      }
    }

    .show2{
      width: 100vw;
      .num1{
        width: 50%;
        height: 109px;
        background: #FFFFFF;
        border: 1px solid #5B86DD;
        color: #5B86DD;
      }
      .showNum1{
        height: 119px;
        padding-bottom: 10px;
        border: none;
        background: url('http://www.ruizhancq.com/common/allline/panel_xuanzhong.png') no-repeat;
        background-size: 100% 100%;
        color: #fff;
      }

      .box1{
        width: 100vw;
        height: 950px;
        padding: 0px 560px;
        // background: url('../assets/img/allline/zhanxianxingshi.png') no-repeat;
        // background-size: 100% 100%;
        .img{
          width: 280px;
          height: 560px;
        }
        .img:nth-of-type(:not(2)){
          margin-bottom: 83px;
        }
        .img:nth-of-type(2){
          margin-top: 83px;
        }
      }

      @media screen and (min-width: 1500px) and (max-width: 2200px) {
        .box1{
          .img{
            width: 260px;
            height: 520px;
          }
          .img:nth-of-type(:not(2)){
            margin-bottom: 63px;
          }
          .img:nth-of-type(2){
            margin-top: 63px;
          }
        }
      }
      @media screen and (min-width: 1800px) and (max-width: 2000px) {
        .box1 {
          height: 800px;
          padding: 0px 460px;
        }
      }
      @media screen and (min-width: 1650px) and (max-width: 1800px) {
        .box1 {
          height: 800px;
          padding: 0px 360px;
        }
      }
      @media screen and (min-width: 750px) and (max-width: 1650px) {
        .box1 {
          height: 700px;
          padding: 0px 220px;
        }
      }

      .box2{
        width: 100vw;
        height: 950px;
        // background: url('../assets/img/allline/zhanxianxingshi.png') no-repeat;
        // background-size: 100% 100%;
        position: relative;
        >.img1{
          width: 560px;
          height: 400px;
          position: absolute;
          top: 90px;
          left: 0px;
          z-index: 10;
        }
        >.img2{
          width: 765px;
          height: 460px;
          position: absolute;
          bottom: 90px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 20;
        }
        >.img3{
          width: 560px;
          height: 400px;
          position: absolute;
          top: 150px;
          right: 0px;
          z-index: 30;
        }
      }
      @media screen and (min-width: 1500px) and (max-width: 2200px) {
        .box2 {
          height: 800px;
          >.img1{
            width: 500px;
            height: 360px;
            position: absolute;
            top: 90px;
            left: 0px;
            z-index: 10;
          }
          >.img2{
            width: 705px;
            height: 420px;
            position: absolute;
            bottom: 90px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 20;
          }
          >.img3{
            width: 500px;
            height: 360px;
            position: absolute;
            top: 150px;
            right: 0px;
            z-index: 30;
          }
        }
      }
      @media screen and (min-width: 750px) and (max-width: 1500px) {
        .box2 {
          height: 700px;
          >.img1{
            width: 450px;
            height: 310px;
            position: absolute;
            top: 90px;
            left: 0px;
            z-index: 10;
          }
          >.img2{
            width: 655px;
            height: 370px;
            position: absolute;
            bottom: 90px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 20;
          }
          >.img3{
            width: 450px;
            height: 310px;
            position: absolute;
            top: 150px;
            right: 0px;
            z-index: 30;
          }
        }
      }
      @media screen and (max-width: 750px){
        .num1{
          height: 5.6771vw;
        }
        .showNum1{
          height: 6.1979vw;
          padding-bottom: 0.5208vw;
        }
        .box1{
          height: 49.4792vw;
          padding: 0px 18.75vw;
          .img{
            width: 14.5833vw;
            height: 29.1667vw;
          }
          .img:nth-of-type(:not(2)){
            margin-bottom: 6.25vw;
          }
          .img:nth-of-type(2){
            margin-top: 6.25vw;
          }
        }
        .box2{
          height: 49.4792vw;
          >.img1{
            width: 32vw;
            height: 22vw;
            top: 4.6875vw;
          }
          >.img2{
            width: 42vw;
            height: 26vw;
            bottom: 4.6875vw;
          }
          >.img3{
            width: 32vw;
            height: 22vw;
            top: 7.8125vw;
          }
        }
      }
    }

    .show3{
      width: 100vw;
      .num1{
        width: 50%;
        height: 109px;
        background: #FFFFFF;
        border: 1px solid #5B86DD;
        color: #5B86DD;
      }
      .showNum1{
        height: 119px;
        padding-bottom: 10px;
        border: none;
        background: url('http://www.ruizhancq.com/common/allline/panel_xuanzhong.png') no-repeat;
        background-size: 100% 100%;
        color: #fff;
      }
      .box1{
        width: 100vw;
        height: 800px;
        // background: url('../assets/img/allline/zhanxianxingshi.png') no-repeat;
        // background-size: 100% 100%;
        position: relative;
        >.img1{
          width: 1000px;
          height: 595px;
          position: absolute;
          top: 120px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 10;
        }
        >.img2{
          width: 1144px;
          height: 318px;
          position: absolute;
          top: 200px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 20;
        }
      }
      @media screen and (min-width: 750px) and (max-width: 1500px) {
        .box1 {
          height: 700px;
          >.img1{
            width: 800px;
            height: 495px;
            position: absolute;
            top: 120px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 10;
          }
          >.img2{
            width: 944px;
            height: 218px;
            position: absolute;
            top: 180px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 20;
        }
        }
      }

      .box2{
        width: 100vw;
        height: 950px;
        padding: 0px 560px;
        // background: url('../assets/img/allline/zhanxianxingshi.png') no-repeat;
        // background-size: 100% 100%;
        .img{
          width: 280px;
          height: 560px;
        }
        .img:nth-of-type(:not(2)){
          margin-bottom: 83px;
        }
        .img:nth-of-type(2){
          margin-top: 83px;
        }
      }

      @media screen and (min-width: 1500px) and (max-width: 2200px) {
        .box2{
          .img{
            width: 260px;
            height: 520px;
          }
          .img:nth-of-type(:not(2)){
            margin-bottom: 63px;
          }
          .img:nth-of-type(2){
            margin-top: 63px;
          }
        }
      }
      @media screen and (min-width: 1800px) and (max-width: 2000px) {
        .box2 {
          height: 800px;
          padding: 0px 460px;
        }
      }
      @media screen and (min-width: 1650px) and (max-width: 1800px) {
        .box2 {
          height: 800px;
          padding: 0px 360px;
        }
      }
      @media screen and (min-width: 750px) and (max-width: 1650px) {
        .box2 {
          height: 700px;
          padding: 0px 220px;
        }
      }
      @media screen and (max-width: 750px){
        .num1{
          height: 5.6771vw;
        }
        .showNum1{
          height: 6.1979vw;
          padding-bottom: 0.5208vw;
        }
        .box1 {
          height: 36.4583vw;
          >.img1{
            width: 41.6667vw;
            height: 25.78125vw;
            top: 6.25vw;
          }
          >.img2{
            width: 49.1667vw;
            height: 11.3542vw;
            top: 9.375vw;
          }
        }
        .box2{
          height: 49.4792vw;
          padding: 0px 18.75vw;
          .img{
            width: 14.5833vw;
            height: 29.1667vw;
          }
          .img:nth-of-type(:not(2)){
            margin-bottom: 6.25vw;
          }
          .img:nth-of-type(2){
            margin-top: 6.25vw;
          }
        }
      }
    }
  }

  .caseBox{
    width: 100vw;
    .case{
      width: 100vw;
      height: 800px;
      // background: url('../assets/img/allline/tuiguanganli.png') no-repeat;
      // background-size: 100% 100%;
      .case1{
        width: 55%;
        >div{
          width: 404px;
          height: 620px;
          position: relative;
          >.img1{
            width: 300px;
            height: 600px;
          }
          >.img2{
            width: 404px;
            height: 305px;
            position: absolute;
            top: 100px;
            left: 0px;
          }
        }
        @media screen and (min-width: 1500px) and (max-width: 1800px) {
          >div{
            width: 374px;
            height: 560px;
            position: relative;
            >.img1{
              width: 270px;
              height: 540px;
            }
            >.img2{
              width: 374px;
              height: 265px;
              position: absolute;
              top: 100px;
              left: 0px;
            }
          }
        }
        @media screen and (min-width: 750px) and (max-width: 1500px) {
          >div{
            width: 344px;
            height: 560px;
            position: relative;
            >.img1{
              width: 240px;
              height: 480px;
            }
            >.img2{
              width: 344px;
              height: 225px;
              position: absolute;
              top: 100px;
              left: 0px;
            }
          }
        }
      }
    }
    @media screen and (min-width: 750px) and (max-width: 1500px){
      .case{
        width: 100vw;
        height: 700px;
        .case1{
          width: 65%;
        }
      }
    }
    @media screen and (max-width: 750px){
      .case{
        height: 41.6667vw;
        .case1{
          >div{
            width: 21.0417vw;
            height: 32.2917vw;
            >.img1{
              width: 15.625vw;
              height: 31.25vw;
            }
            >.img2{
              width: 21.0417vw;
              height: 15.8854vw;
              top: 5.2083vw;
            }
          }
        }
      }
    }
  }


}
</style>
