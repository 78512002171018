<template>
  <div class="otherBox column align_center white_color ft20">
    <ul class="row flex_center">
      <li class="row align_center">
        <img class="wh70" src="http://www.ruizhancq.com/common/icon_cehuashejifuwu.png" alt="" />
        <div class="ml20">
          <div>策划设计服务</div>
          <div class="mt10 text_nowrap">Planning and design services</div>
        </div>
        <img
          class="wh50 ml110 cursor"
          src="http://www.ruizhancq.com/common/icon_jinqu.png"
          alt=""
          @click="handleCollaborate"
        />
      </li>
      <div class="line_up"></div>
      <li class="row align_center ml80">
        <img class="wh70" src="http://www.ruizhancq.com/common/icon_qiatanhezuo.png" alt="" />
        <div class="ml20">
          <div>洽谈合作</div>
          <div class="mt10 text_nowrap">Cooperation negotiation</div>
        </div>
        <img
          class="wh50 ml152 cursor"
          src="http://www.ruizhancq.com/common/icon_jinqu.png"
          alt=""
          @click="handleCollaborate"
        />
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  props: {
    current: Number,
  },
  methods: {
    handleCollaborate() {
      if (this.current == 4) {
        var el = document.getElementById("lianxi")
        this.$nextTick(function () {
          window.scrollTo({
            behavior: "smooth",
            top: el.offsetTop + -88,
          })
        })
      } else {
        this.$router.push({
          path: "/aboutus",
        })
        setTimeout(()=>{
          var el = document.getElementById("lianxi")
          this.$nextTick(function () {
            window.scrollTo({
              behavior: "smooth",
              top: el.offsetTop + -88,
            })
          })
        },100)
      }
    },
  },
}
</script>
<style lang="scss">
.otherBox {
  width: 100vw;
  height: 150px;
  padding: 0px 460px;
  background: url("http://www.ruizhancq.com/common/jpg4.png") no-repeat;
  background-size: 100% 100%;
  ul {
    width: 100%;
    height: 100%;
  }
}
@media screen and (min-width: 1800px) and (max-width: 2000px) {
  .otherBox {
    padding: 0px 360px;
  }
}
@media screen and (min-width: 1650px) and (max-width: 1800px) {
  .otherBox {
    padding: 0px 200px;
  }
}
@media screen and (min-width: 750px) and (max-width: 1650px) {
  .otherBox {
    padding: 0px 100px;
  }
}
.line_up {
  width: 0px;
  height: 50px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  margin-left: 80px;
}
@media screen and (max-width: 1650px){
  .otherBox{
    height: 9.125vw;
    padding: 0px 18.75vw;
  }
  .line_up {
    height: 2.6042vw;
    margin-left: 4.1667vw;
  }
}
</style>
