import axios from "axios";
import { getToken } from "./auth";
import router from "../router/index"
// 创建axios

const server = axios.create({
    // baseURL: "/", 
    // baseURL: "http://ruizhan.xxzcloud.com",
    // baseURL:"http://admin.ruizhancq.com",//配置请求的公共前缀
    baseURL:"https://admin.ruizhancq.com",//配置请求的公共前缀
    timeout: 6000,
    // headers: { //请求头
    //     client: "web"
    // }
})

/* 请求拦截器 + 相应拦截器  */

server.interceptors.request.use((config) => {

    config.headers.Authorization = getToken()
    return config

})


/* 
  统一处理相应的
*/

server.interceptors.response.use((res) => {

    /* 成功回来之后，把数据给到之前执行的  */
    
    // if(!res.code){

    //     if(res.code == -10){

    //         window.localStorage.removeItem("token");
    //         router.push("/index")

    //     }

    // }

    return res

})



export default server