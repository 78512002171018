<template>
  <div id="app" class="ft32">
    <router-link to="/"></router-link>
    <router-link to="/allline"></router-link>
    <router-link to="/newmedia"></router-link>
    <router-link to="/news"></router-link>
    <router-link to="/news/newsdetail"></router-link>
    <router-link to="/aboutus"></router-link>
    <router-view />
  </div>
</template>

<script>
  import Wow  from 'wow.js'
  import { routes } from "@/router";
  import {getSeoList} from '@/api/app'
  export default {
    data(){
      return {
        tit:{},
      }
    },
    routes() {
      return routes[0].children;
    },
    created() {
      // this.fetchSeoList()
    },
    mounted(){
      console.log(routes);
      const wow = new Wow({
        boxClass: 'wow',
        animateClass: 'animate__animated',
        // 这个对应的是你的动画的前缀类名
        mobile: true,
        live: true
      })
      wow.init()
    },
    methods:{
      async fetchSeoList() {
        let {data} = await getSeoList()
        this.tit = data.data.val
        console.log('tt',this.tit);
      },
    }
  }
</script>

<style lang="scss">
*{
  box-sizing: border-box;
}
ul{
  margin: 0px;
  padding: 0px;
}
li{
  list-style: none;
}
#app {
  font-family: Noto Sans SC,Noto Sans SC-Medium,Noto Sans SC-Regular,Noto Sans SC-Bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button{
  border: none !important;
  outline: none !important;
  color: #fff !important;
}
</style>
