import Vue from 'vue'
import VueRouter from 'vue-router'

import NoFound from "../views/NoFound"
import RuiZhan from "../views/RuiZhan"
import AllLine from "../views/AllLine"
import NewMedia from "../views/NewMedia"
import News from "../views/News"
import NewsDetail from "../views/NewsDetail"
import AboutUs from "../views/AboutUs"
import {getSeoList} from '@/api/app'

Vue.use(VueRouter); //使用插件

export const routes = [
  { //一级路由
    path: '/', // 根路由 
    name: 'index',
    component: RuiZhan,
    meta:{
      isShow:true,
      title:'',
      content:{
        title:'',
        site_name:'',
        // keywords:'',
        description:'',
      }
    }
    // redirect: "/index", //重定向
  },
  {
    path: '/allline',
    name: 'allline',
    component: AllLine,
  },
  {
    path: '/newmedia',
    name: 'newmedia',
    component: NewMedia,
  },
  {
    path: '/news',
    name: 'news',
    component: News,
  },
  {
    path: '/newsdetail',
    name: 'newsdetail',
    component: NewsDetail,
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    component: AboutUs,
  },
  {
    path: '*',
    component: NoFound
  }
]

const router = new VueRouter({
  routes,
  mode: 'hash' //路由匹配模式  hash 
})

// router.afterEach((to,from,next)=>{
//   // const fetchSeoList = async () => {
//   //   let {data} = await getSeoList()
//   //   return data.data.val
//   // }
//   // let tit = fetchSeoList()
//   // console.log('tt',tit);
//   console.log('to,from,next',to,from,next);
//   if (to.meta.content) {
//     const head = document.getElementsByTagName('head')[0];
//     // 删除现有名称为 "keywords" 和 "description" 的 meta 标签
//     const existingKeywordsMeta = document.querySelector('meta[name="keywords"]');
//     if (existingKeywordsMeta) {
//         existingKeywordsMeta.remove(); // 移除现有的关键词 meta 标签
//     }
//     const existingDescriptionMeta = document.querySelector('meta[name="description"]');
//     if (existingDescriptionMeta) {
//         existingDescriptionMeta.remove(); // 移除现有的描述 meta 标签
//     }

//     // 创建并设置新的关键词和描述 meta 标签
//     const keywordsMeta = document.createElement('meta');
//     keywordsMeta.setAttribute('name', 'keywords');
//     keywordsMeta.setAttribute('content', to.meta.content.keywords);
//     head.appendChild(keywordsMeta); // 添加新的关键词 meta 标签

//     const descriptionMeta = document.createElement('meta');
//     descriptionMeta.setAttribute('name', 'description');
//     descriptionMeta.setAttribute('content', to.meta.content.description);
//     head.appendChild(descriptionMeta); // 添加新的描述 meta 标签

//     // 可选：如果需要，更新名为 "content" 的其他 meta 标签
//     const contentMeta = document.querySelector('meta[name="content"]');
//     if (contentMeta) {
//         contentMeta.setAttribute('content', to.meta.content); // 更新 content 属性
//     }
//   }
// })


/* 
  权限控制：把不需要登录的页面 称为 白名单 

*/

export default router
