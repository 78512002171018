<template>
  <div class="main primary_color">
    <!-- nav -->
    <my-nav :current="current" @onHandleShow="handleShow"></my-nav>

    <!-- banner -->
    <div class="bannerBox">
      <el-carousel :height="bannerHeight" arrow="never" :interval="1700">
        <el-carousel-item v-for="(item) in banners" :key="item.id">
          <div class="banner column justify_center">
            <img :src="item.thumb" alt="" />
            <div class="ban white_color">
              <div class="ft48 bloder">{{ item.title }}</div>
              <div class="ft18 mt30">{{ item.name }}</div>
              <button class="btn_p mt50 cursor ft20" @click="handleCollaborate">
                立即体验
              </button>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- 多线程·全场景联动引流 -->
    <div id="liandong" class="lineBox">
      <!-- 线上 -->
      <div class="column align_center">
        <div class="mt100 ft32 bolder">多线程·全场景联动引流</div>
        <div class="line mt20"></div>
        <div class="mt60 ft28">多线上场景覆盖·多网络平台营销</div>
        <div class="online column flex_center mt40">
          <div class="row align_center">
            <div class="prev cursor" :class="one==0?'noprev':''" @click="prevBtn"></div>
            <div class="w900" style="overflow: hidden;">
              <ul class="row" :style="'margin-left:'+mar+'px'">
                <li
                  class="column flex_center br20 bg_black"
                  v-for="(item) in online"
                  :key="item.id"
                >
                  <img :src="item.thumb" alt="" />
                  <div class="white_color ft18 mt16">{{ item.title }}</div>
                </li>
              </ul>
            </div>
            <!-- <ul class="row justify_sb">
              <li
                class="column flex_center br20 bg_black wow animate__animated animate__zoomIn"
                v-for="(item, index) in online"
                :key="item.id"
                data-wow-duration="0.8s"
                :data-wow-delay="0.2 * index + 's'"
                data-wow-iteration="1"
              >
                <img :src="item.thumb" alt="" />
                <div class="white_color ft18 mt16">{{ item.title }}</div>
              </li>
            </ul> -->
            <div class="next cursor" :class="one==this.one_list.length - 4?'nonext':''" @click="nextBtn"></div>
          </div>
          <!-- <ul class="row justify_sb">
            <li
              class="column flex_center br20 bg_black wow animate__animated animate__zoomIn"
              v-for="(item, index) in online"
              :key="item.id"
              data-wow-duration="0.8s"
              :data-wow-delay="0.2 * index + 's'"
              data-wow-iteration="1"
            >
              <img :src="item.thumb" alt="" />
              <div class="white_color ft18 mt16">{{ item.title }}</div>
            </li>
          </ul> -->
          <button class="btn_p mt100 cursor ft20" style="" @click="handleCollaborate">
            立即获取专属流量
          </button>
        </div>
      </div>
      <!-- 线下 -->
      <div class="column align_center">
        <div
          class="mt100 ft28 wow animate__animated animate__fadeInUp"
          data-wow-duration="0.8s"
          data-wow-delay="0.2s"
          data-wow-iteration="1"
        >
          线下场景
        </div>
        <div
          class="mt20 ft20 wow animate__animated animate__fadeInUp"
          data-wow-duration="0.8s"
          data-wow-delay="0.2s"
          data-wow-iteration="1"
        >
          触达生活各角落，辐射全场景服务
        </div>
        <div class="" style="overflow-y: hidden;overflow-x: scroll;width: 100vw;">
          <ul class="outline white_color mt40">
            <li
              v-for="(item, index) in outline"
              :key="item.id"
              class="wow animate__animated animate__flipInX"
              data-wow-duration="0.7s"
              :data-wow-delay="(index % 2) * 0.2 + 0.2 + 's'"
              data-wow-iteration="1"
            >
              <img :src="item.thumb" alt="" />
              <div class="outText">
                <div class="ft20">{{ item.title }}</div>
                <div class="line_w mt12"></div>
              </div>
            </li>
          </ul>
        </div>
        <!-- <ul class="outline white_color mt40">
          <li
            v-for="(item, index) in outline"
            :key="item.id"
            class="wow animate__animated animate__flipInX"
            data-wow-duration="0.7s"
            :data-wow-delay="(index % 2) * 0.2 + 0.2 + 's'"
            data-wow-iteration="1"
          >
            <img :src="item.thumb" alt="" />
            <div class="outText">
              <div class="ft20">{{ item.title }}</div>
              <div class="line_w mt12"></div>
            </div>
          </li>
        </ul> -->
      </div>
      <!-- 线上+线下 -->
      <div class="column align_center">
        <div
          class="mt100 ft28 wow animate__animated animate__fadeInUp"
          data-wow-duration="0.8s"
          data-wow-delay="0.2s"
          data-wow-iteration="1"
        >
          线上 ➕ 线下
        </div>
        <div
          class="mt20 ft20 wow animate__animated animate__fadeInUp"
          data-wow-duration="0.8s"
          data-wow-delay="0.2s"
          data-wow-iteration="1"
        >
          双线合作•线上引流•线下成交
        </div>
        <div class="onandOut column align_center white_color mt40">
          <div
            class="mt50 ft26 wow animate__animated animate__fadeInRight"
            data-wow-duration="0.6s"
            data-wow-delay="0.2s"
            data-wow-iteration="1"
          >
            为您的企业以及产品进行线上 + 线下的推广战略
          </div>
          <ul class="mt130 ft20 column justify_sb">
            <li
              v-for="(item, index) in lines"
              :key="item.id"
              class="br8 bg_notice row wow animate__animated animate__fadeInRight"
              data-wow-duration="0.6s"
              :data-wow-delay="(index % 2) * 0.2 + 0.2 + 's'"
              data-wow-iteration="1"
            >
              <img class="wh80" :src="item.thumb" alt="" />
              <div class="ml35 column justify_center">
                <div>{{ item.title }}</div>
                <div class="light_color ft16 mt12">{{ item.name }}</div>
              </div>
            </li>
            <!-- <li
              class="br8 bg_notice row wow animate__animated animate__fadeInRight"
              data-wow-duration="0.6s"
              data-wow-delay="0.4s"
              data-wow-iteration="1"
            >
              <img
                class="wh80"
                src="../assets/img/index/icon_xianshangyinliu.png"
                alt=""
              />
              <div class="ml35 column justify_center">
                <div>线上引流</div>
                <div class="light_color ft16 mt12">On-line drainage</div>
              </div>
            </li>
            <li
              class="br8 bg_notice row wow animate__animated animate__fadeInRight"
              data-wow-duration="0.6s"
              data-wow-delay="0.6s"
              data-wow-iteration="1"
            >
              <img
                class="wh80"
                src="../assets/img/index/icon_xianxiachengjiao.png"
                alt=""
              />
              <div class="ml35 column justify_center">
                <div>线下成交</div>
                <div class="light_color ft16 mt12">Offline collaboration</div>
              </div>
            </li> -->
          </ul>
        </div>
      </div>
    </div>

    <!-- 量身定制品牌推广方案 -->
    <div id="fangan" class="programBox column align_center">
      <div
        class="mt100 ft32 bolder wow animate__animated animate__fadeInUp"
        data-wow-duration="0.8s"
        data-wow-delay="0.2s"
        data-wow-iteration="1"
      >
        量身定制品牌推广方案
      </div>
      <div
        class="line mt20 wow animate__animated animate__fadeInUp"
        data-wow-duration="0.8s"
        data-wow-delay="0.2s"
        data-wow-iteration="1"
      ></div>
      <ul class="mt100 row justify_sb">
        <li
          class="wow animate__animated animate__fadeInUp"
          data-wow-duration="0.8s"
          data-wow-iteration="1"
        >
          <div class="row align_center pl160">
            <img class="wh70" :src="fourList[0].thumb ? fourList[0].thumb : ''" alt="" />
            <div class="ml20 ft24 text_nowrap">{{ fourList[0].title }}</div>
          </div>
          <div class="row ft16">
            <div class="column align_end">
              <div class="column align_center">
                <div class="mt26">{{ four_text_one[0].title }}</div>
                <div>{{ four_text_one[2].title }}</div>
              </div>
              <div class="column align_center">
                <div class="mt34">{{ four_text_one[4].title }}</div>
                <div>{{ four_text_one[6].title }}</div>
              </div>
            </div>
            <div class="row">
              <div class="ml20 mt33">
                <div class="up_solid"></div>
                <div class="up_solid mt48"></div>
              </div>
              <div class="ml10">
                <div class="left_dashed mt56"></div>
                <div class="left_dashed mt94"></div>
              </div>
              <div class="up_dashed"></div>
              <div class="">
                <div class="right_dashed mt56"></div>
                <div class="right_dashed mt94"></div>
              </div>
              <div class="ml10 mt33">
                <div class="up_solid"></div>
                <div class="up_solid mt48"></div>
              </div>
            </div>
            <div class="column ml20">
              <div class="column align_center">
                <div class="mt26">{{ four_text_one[1].title }}</div>
                <div>{{ four_text_one[3].title }}</div>
              </div>
              <div class="column align_center">
                <div class="mt34">{{ four_text_one[5].title }}</div>
                <div>{{ four_text_one[7].title }}</div>
              </div>
            </div>
          </div>
        </li>
        <li
          class="wow animate__animated animate__fadeInUp"
          data-wow-duration="0.8s"
          data-wow-iteration="1"
        >
          <div class="row align_center">
            <img class="wh70" :src="fourList[1].thumb" alt="" />
            <div class="ml20 ft24 text_nowrap">{{ fourList[1].title }}</div>
          </div>
          <div class="row ft16">
            <div class="row">
              <div class="up_dashed ml35"></div>
              <div class="">
                <div class="right_dashed mt56"></div>
                <div class="right_dashed mt94"></div>
              </div>
              <div class="ml10 mt33">
                <div class="up_solid"></div>
                <div class="up_solid mt48"></div>
              </div>
            </div>
            <div class="column ml20">
              <div class="column align_center">
                <div class="mt26">{{ four_text_one[0].title }}</div>
                <div>{{ four_text_one[1].title }}</div>
              </div>
              <div class="column align_center">
                <div class="mt34">{{ four_text_one[2].title }}</div>
                <div>{{ four_text_one[3].title }}</div>
              </div>
            </div>
          </div>
        </li>
        <li
          class="column align_center wow animate__animated animate__fadeInUp"
          data-wow-duration="0.8s"
          data-wow-iteration="1"
        >
          <div class="row align_center">
            <img class="wh70" :src="fourList[2].thumb" alt="" />
            <div class="ml20 ft24 text_nowrap">{{ fourList[2].title }}</div>
          </div>
          <div class="row ft16">
            <div class="column ml20">
              <div class="column align_center">
                <div class="mt26">{{ four_text_three[0].title }}</div>
                <div>➕</div>
                <div>{{ four_text_three[1].title }}</div>
              </div>
            </div>
          </div>
        </li>
        <li
          class="column align_center wow animate__animated animate__fadeInUp"
          data-wow-duration="0.8s"
          data-wow-iteration="1"
        >
          <div class="row align_center">
            <img class="wh70" :src="fourList[2].thumb" alt="" />
            <div class="ml20 ft24 text_nowrap">{{ fourList[3].title }}</div>
          </div>
          <div class="row ft16">
            <div class="column ml20">
              <div class="column align_center">
                <div class="mt26">{{ four_text_four[0].title }}</div>
                <div>{{ four_text_four[1].title }}</div>
                <div>{{ four_text_four[2].title }}</div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <!-- 为什么要做品牌推广引流 -->
    <div id="yinliu" class="whyBox column align_center mt100">
      <div
        class="mt100 ft32 bolder wow animate__animated animate__fadeInUp"
        data-wow-duration="0.8s"
        data-wow-delay="0.2s"
        data-wow-iteration="1"
      >
        为什么要做品牌推广引流
      </div>
      <div
        class="line mt20 wow animate__animated animate__fadeInUp"
        data-wow-duration="0.8s"
        data-wow-delay="0.2s"
        data-wow-iteration="1"
      ></div>
      <div class="why column align_center mt78">
        <!-- <img :src="five_val.thumb" alt="" style="width: 80%" /> -->
        <div class="row align_center mt32">
          <div class="shadow_light bg_light row flex_center">自己运营</div>
          <div class="ft40 bolder">VS</div>
          <div class="shadow_dark bg_dark row flex_center white_color">
            托管运营
          </div>
        </div>
        <div class="ft16 mt60 row">
          <ul class="column align_end">
            <li
              class="bg_light br4 row flex_center wow animate__animated animate__fadeInRight"
              data-wow-duration="0.6s"
              :data-wow-delay="0.1*index + 0.2 + 's'"
              data-wow-iteration="1" v-for="(item,index) in five_text[0].child_text" :key="item.id"
            >
              {{ item.title }}
            </li>
            <!-- <li
              class="bg_light br4 row flex_center wow animate__animated animate__fadeInRight"
              style="margin-right: -23px"
              data-wow-duration="0.6s"
              data-wow-delay="0.3s"
              data-wow-iteration="1"
            >
              没有品牌宣传，无法曝光人前
            </li>
            <li
              class="bg_light br4 row flex_center wow animate__animated animate__fadeInRight"
              data-wow-duration="0.6s"
              data-wow-delay="0.4s"
              data-wow-iteration="1"
            >
              没有品牌信任度，影响消费者购买决策
            </li>
            <li
              class="bg_light br4 row flex_center wow animate__animated animate__fadeInRight"
              style="margin-right: -23px"
              data-wow-duration="0.6s"
              data-wow-delay="0.5s"
              data-wow-iteration="1"
            >
              没有品牌权威度，缺乏权威媒体站台
            </li>
            <li
              class="bg_light br4 row flex_center wow animate__animated animate__fadeInRight"
              style="margin-right: -72px"
              data-wow-duration="0.6s"
              data-wow-delay="0.6s"
              data-wow-iteration="1"
            >
              没有品牌讨论度，企业品牌活力不足
            </li> -->
          </ul>
          <div class="earth">
            <img :src="five_val.thumb" alt="" />
          </div>
          <ul class="white_color column">
            <li
              class="bg_dark br4 row flex_center wow animate__animated animate__fadeInLeft"
              data-wow-duration="0.6s"
              :data-wow-delay="0.1*index + 0.2 + 's'"
              data-wow-iteration="1" v-for="(item,index) in five_text[1].child_text" :key="item.id"
            >
              {{ item.title }}
            </li>
            <!-- <li
              class="bg_dark br4 row flex_center wow animate__animated animate__fadeInLeft"
              style="margin-left: -23px"
              data-wow-duration="0.6s"
              data-wow-delay="0.3s"
              data-wow-iteration="1"
            >
              深挖品牌优势与亮点，创造卖点
            </li>
            <li
              class="bg_dark br4 row flex_center wow animate__animated animate__fadeInLeft"
              data-wow-duration="0.6s"
              data-wow-delay="0.4s"
              data-wow-iteration="1"
            >
              提高品牌认同感，积累忠实用户
            </li>
            <li
              class="bg_dark br4 row flex_center wow animate__animated animate__fadeInLeft"
              style="margin-left: -23px"
              data-wow-duration="0.6s"
              data-wow-delay="0.5s"
              data-wow-iteration="1"
            >
              树立企业形象，引领行业标杆
            </li>
            <li
              class="bg_dark br4 row flex_center wow animate__animated animate__fadeInLeft"
              style="margin-left: -72px"
              data-wow-duration="0.6s"
              data-wow-delay="0.6s"
              data-wow-iteration="1"
            >
              完善品牌营销策略，建立品牌体系
            </li> -->
          </ul>
        </div>
      </div>
    </div>

    <!-- 品牌推广流程 -->
    <div id="liucheng" class="progressBox column align_center">
      <div
        class="mt100 ft32 bolder wow animate__animated animate__fadeInUp"
        data-wow-duration="0.8s"
        data-wow-delay="0.2s"
        data-wow-iteration="1"
      >
        品牌推广流程
      </div>
      <div
        class="line mt20 wow animate__animated animate__fadeInUp"
        data-wow-duration="0.8s"
        data-wow-delay="0.2s"
        data-wow-iteration="1"
      ></div>
      <div class="progress column align_center mt110">
        <ul class="row justify_sb">
          <li class="wh110" v-for="item in progress" :key="item.id">
            <img  class="wh110" :src="item.thumb" alt="" />
          </li>
        </ul>
        <div class="dotLine mt60">
          <ul class="row align_center justify_sb">
            <li v-for="(_, index) in 6" :key="index"></li>
          </ul>
        </div>
        <ul class="mt60 row ft22 info_color">
          <li
            class="column align_center"
            v-for="(item, index) in progress"
            :key="index"
          >
            <div class="ft36">0{{ index + 1 }}</div>
            <div class="mt15">{{ item.title }}</div>
          </li>
          <!-- <li class="column align_center">
            <div class="ft36">02</div>
            <div class="mt15">量身打造营销方案</div>
          </li>
          <li class="column align_center">
            <div class="ft36">03</div>
            <div class="mt15">签订合同</div>
          </li>
          <li class="column align_center">
            <div class="ft36">04</div>
            <div class="mt15">方案执行</div>
          </li>
          <li class="column align_center">
            <div class="ft36">05</div>
            <div class="mt15">全流程跟踪及时优化</div>
          </li>
          <li class="column align_center">
            <div class="ft36">06</div>
            <div class="mt15">效果反馈</div>
          </li> -->
        </ul>
      </div>
    </div>

    <!-- 团队优势 -->
    <div id="youshi" class="advantageBox mt100 column align_center">
      <div
        class="mt100 ft32 bolder white_color wow animate__animated animate__fadeInUp"
        data-wow-duration="0.8s"
        data-wow-delay="0.2s"
        data-wow-iteration="1"
      >
        团队优势
      </div>
      <div
        class="line mt20 wow animate__animated animate__fadeInUp"
        data-wow-duration="0.8s"
        data-wow-delay="0.2s"
        data-wow-iteration="1"
      ></div>
      <ul class="mt120 row justify_sb align_center white_color">
        <li class="" v-for="(item, index) in advantage" :key="index">
          <div class="line_b" v-if="item.isLine"></div>
          <div class="column flex_center br2 shadow_item bd_white" v-else>
            <img  class="wh60" :src="item.thumb" alt="" />
            <div class="mt20 ft18">{{ item.title }}</div>
          </div>
        </li>
      </ul>
      <div
        id="zixun"
        class="formBox bg_w br8 shadow_form mt120 column align_center"
      >
        <div
          class="mt80 ft32 bolder"
          data-wow-duration="0.8s"
          data-wow-delay="0.2s"
          data-wow-iteration="1"
        >
          点击咨询
        </div>
        <div
          class="line mt20"
          data-wow-duration="0.8s"
          data-wow-delay="0.2s"
          data-wow-iteration="1"
        ></div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="0px"
          class="form mt67"
        >
          <div class="row wrap justify_sb ft20">
            <el-form-item prop="name" class="input">
              <el-input
                v-model="ruleForm.name"
                placeholder="您的称呼"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item prop="mobile" class="input">
              <el-input
                v-model="ruleForm.mobile"
                placeholder="联系电话"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item prop="qqWeChart" class="input mt50">
              <el-input
                v-model="ruleForm.qqWeChart"
                placeholder="微信或QQ"
                clearable
              ></el-input>
            </el-form-item>
          </div>
          <div class="mt50">
            <div class="">推广选择</div>
            <el-form-item prop="radio">
              <el-radio-group v-model="ruleForm.radio" class="mt50 input_color">
                <el-radio :label="1">全网营销</el-radio>
                <el-radio :label="2">新媒体营销</el-radio>
                <el-radio :label="3">全网营销 + 新媒体营销</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
        </el-form>
        <div
          class="subBtn mt100 bg_primary white_color ft24 row flex_center cursor"
          @click="to_sub"
        >
          提交
        </div>
      </div>
    </div>

    <!-- other -->
    <my-other :current="current"></my-other>

    <!-- footer -->
    <my-footer :current="current" @onScrollTo="scroll"></my-footer>
  </div>
</template>

<script>
import {getSeoList, getIndex, consultSub} from "@/api/app"

export default {
  metaInfo() {
    return {
      title: this.PageTitle,
      meta: [
        {
          name: "keywords",
          content: this.PagekeyWords,
        },
        // {
        //   name: "title",
        //   content: this.PageTitle,
        // },
        {
          name: "description",
          content: this.PageDescription,
        },
        {
          property: "og:type",
          content: 'website',
        },
        {
          property: "og:title",
          content: this.PageTitle,
        },
        {
          property: "og:description",
          content: this.PageDescription,
        },
        {
          property: "og:site_name",
          content: this.PageTitle,
        },
        {
          property: "og:url",
          content: 'https://www.ruizhancq.com',
        },
        {
          property: "og:image",
          content: 'https://www.ruizhancq.com/favicon.ico',
        },
      ],
    };
  },
  data() {
    return {
      PageTitle: "",
      PagekeyWords: "",
      PageDescription: "",
      one:0,
      ruleForm: {
        name: "",
        mobile: "",
        qqWeChart: "",
        radio: 1,
      },
      rules: {
        name: [{required: true, message: "请输入您的称呼", trigger: "blur"}],
        mobile: [{required: true, message: "请输入联系电话", trigger: "blur"}],
        qqWeChart: [
          {required: true, message: "请输入微信或QQ", trigger: "blur"},
        ],
      },
      advantage: [],
      progress: [],
      five_val: {},
      five_text:{},
      four_text_one: [],
      four_text_two: [],
      four_text_three: [],
      four_text_four: [],
      fourList: [],
      lines: [],
      outline: [],
      one_list:[],
      online: [],
      banners: [],
      current: 0,
      bannerHeight: "700px",
      mar:0,
    }
  },
  created() {
    // 获取页面宽度，修改banner高度
    const screenWidth = window.innerWidth
    if (screenWidth >= 2100) {
      this.bannerHeight = "820px"
    } else if (screenWidth >= 1800 && screenWidth < 2100) {
      this.bannerHeight = "700px"
    } else if (screenWidth >= 1500 && screenWidth < 1800) {
      this.bannerHeight = "600px"
    } else if (screenWidth >= 750 && screenWidth < 1500) {
      this.bannerHeight = "500px"
    } else {
      this.bannerHeight = "36.4583vw"
    }
  },
  mounted() {
    this.fetchSeoList()

    this.current = 0
    this.fetchIndex()
  },
  methods: {
    async fetchSeoList() {
      let {data} = await getSeoList()
      this.tit = data.data.val
      console.log('tt',this.tit);
      this.PageTitle = this.tit.zd_name
      this.PagekeyWords = this.tit.wz_keyword
      this.PageDescription = this.tit.zd_desc
    },
    prevBtn(){
      if(this.one_list.length>4){
        if(this.one<=0){
          this.one = 0;
          this.online = this.one_list.slice(0,4)
          // this.mar = 0
          return
        }else{
          this.one--;
          this.online = this.one_list.slice(this.one, this.one + 4)
          // this.mar = this.mar + this.one*240
          return
        }
      }else{
        return
      }
    },
    nextBtn(){
      if(this.one_list.length>4){
        if((this.one + 4)>=this.one_list.length){
          this.one = this.one_list.length - 4
          this.online = this.one_list.slice(this.one_list.length - 4)
          // this.mar = 0
          return
        }else{
          this.one++;
          this.online = this.one_list.slice(this.one, this.one + 4)
          // this.mar = this.mar - this.one*240
          return
        }
      }else{
        return
      }
    },
    toNewMedia(){
      this.$router.push({
        path: "/newmedia",
      })
    },
    async to_sub() {
      console.log("表单", this.ruleForm)
      let {data} = await consultSub({
        name: this.ruleForm.name,
        phone: this.ruleForm.mobile,
        wechat: this.ruleForm.qqWeChart,
        type: this.ruleForm.radio,
      })
      if (data.code == 1) {
        this.$message({
          message: "提交成功",
          type: "success",
        })
        this.ruleForm.name = ''
        this.ruleForm.mobile = ''
        this.ruleForm.qqWeChart = ''
        this.ruleForm.radio = 1
      } else {
        this.$message.error(data.msg)
      }
    },
    handleCollaborate(){
      this.$router.push({
        path: "/aboutus",
      })
      setTimeout(()=>{
        var el = document.getElementById("lianxi")
        this.$nextTick(function () {
          window.scrollTo({
            behavior: "smooth",
            top: el.offsetTop + -88,
          })
        })
      },100)
    },
    // handleTry() {
    //   var el = document.getElementById("zixun")
    //     this.$nextTick(function () {
    //       window.scrollTo({
    //         behavior: "smooth",
    //         top: el.offsetTop + -88,
    //       })
    //     })
    // },
    handleShow(index,id) {
      console.log(index)
      this.current = index
      if (index == 1) {
        this.$router.push({
          path: "/allline",
          query:{
            index:id
          }
        })
      }
      if (index == 2) {
        this.$router.push({
          path: "/newmedia",
          query:{
            index:id
          }
        })
      }
      if (index == 3) {
        this.$router.push({
          path: "/news",
        })
        this.handelUpdate()
        if(id){
          setTimeout(()=>{
            var el = document.getElementById(id)
            this.$nextTick(function () {
              window.scrollTo({
                behavior: "smooth",
                top: el.offsetTop + -88,
              })
            })
          },100)
        }else{
          return
        }
      }
      if (index == 4) {
        this.$router.push({
          path: "/aboutus",
        })
        this.handelUpdate()
        if(id){
          setTimeout(()=>{
            console.log('222',id);
            var el = document.getElementById(id)
            this.$nextTick(function () {
              window.scrollTo({
                behavior: "smooth",
                top: el.offsetTop + -88,
              })
            })
          },100)
        }else{
          return
        }
      }
      // if(id){
      //   setTimeout(()=>{
      //     console.log('222',id);
      //     var el = document.getElementById(id)
      //     this.$nextTick(function () {
      //       window.scrollTo({
      //         behavior: "smooth",
      //         top: el.offsetTop + -88,
      //       })
      //     })
      //   },100)
      // }else{
      //   return
      // }
    },
    handelUpdate() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
    },
    scroll({id, offset, num}) {
      if (num == -1) {
        offset = offset || -10
        var el = document.getElementById(id)
        this.$nextTick(function () {
          window.scrollTo({
            behavior: "smooth",
            top: el.offsetTop + offset,
          })
        })
      } else {
        this.handleShow(num,id)
      }
    },
    async fetchIndex() {
      let {data} = await getIndex()
      console.log("index", data.data)
      this.banners = data.data.banner_list

      this.one_list = data.data.one_list
      this.online = this.one_list.slice(0,4)
      // this.online = this.one_list
      this.outline = data.data.two_list
      this.lines = data.data.three_list

      this.fourList = data.data.four_list
      this.four_text_one = data.data.four_text_one
      this.four_text_two = data.data.four_text_two
      this.four_text_three = data.data.four_text_three
      this.four_text_four = data.data.four_text_four

      this.five_text = data.data.five_text
      this.five_val = data.data.five_val
      this.progress = data.data.six_list
      // this.advantage = data.data.seven_list
      let temp1 = data.data.seven_list
      let temp2 = []
      temp1.map((item, index) => {
        item.isLine = false
      })
      temp1.map((item, index) => {
        if (index != temp1.length - 1) {
          temp2.push(item, {isLine: true})
        } else {
          temp2.push(item)
        }
      })
      this.advantage = temp2
    },
  },
}
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  min-height: 100%;
  padding: 0px;
  margin: 0px;
  background-color: #fff;
  position: relative;
  overflow-x: hidden;

  .bannerBox {
    width: 100vw;
    height: 820px;
    .banner {
      width: 100vw;
      height: 820px;
      position: relative;
      img {
        width: 100vw;
        height: 820px !important;
        position: absolute;
        top: 0px;
        height: 0px;
        z-index: -1;
      }
      .ban {
        padding-left: 460px;
      }
      @media screen and (min-width: 1800px) and (max-width: 2000px) {
        .ban {
          padding-left: 360px;
        }
      }
      @media screen and (min-width: 1650px) and (max-width: 1800px) {
        .ban {
          padding-left: 200px;
        }
      }
      @media screen and (max-width: 1650px) {
        .ban {
          padding-left: 100px;
        }
      }
    }
  }
  @media screen and (min-width: 1800px) and (max-width: 2100px) {
    .bannerBox {
      height: 700px;
      .banner {
        height: 700px;
        img {
          height: 700px !important;
        }
      }
    }
  }
  @media screen and (min-width: 1500px) and (max-width: 1800px) {
    .bannerBox {
      height: 600px;
      .banner {
        height: 600px;
        img {
          height: 600px !important;
        }
      }
    }
  }
  @media screen and (min-width: 750px) and (max-width: 1500px) {
    .bannerBox {
      height: 500px;
      .banner {
        height: 500px;
        img {
          height: 500px !important;
        }
      }
    }
  }
  @media screen and (max-width: 750px) {
    .bannerBox {
      height: 36.4583vw;
      .banner {
        height: 36.4583vw;
        img {
          height: 36.4583vw !important;
        }
        .ban {
          padding-left: 40px;
        }
      }
    }
  }

  .lineBox {
    width: 100vw;
    .online {
      width: 100%;
      height: 700px;
      padding: 170px 0px 100px 0px;
      background: url("http://www.ruizhancq.com/common/index/jpg.png") no-repeat;
      background-size: 100% 100%;
      position: relative;
      .prev{
        width: 60px;
        height: 60px;
        margin: 0px 40px;
        background: url('http://www.ruizhancq.com/common/news/icon_zuojiantou_lan_60.png');
        display: none;
        position: absolute;
        top: 50%;
        left: 0%;
        transform: translateY(-50%);
      }
      .noprev{
        background: url('http://www.ruizhancq.com/common/news/icon_zuojiantou_60.png');
        display: none;
      }
      .next{
        width: 60px;
        height: 60px;
        margin: 0px 40px;
        background: url('http://www.ruizhancq.com/common/news/icon_youjiantou_60.png');
        display: none;
        position: absolute;
        top: 50%;
        right: 0%;
        transform: translateY(-50%);
      }
      .nonext{
        background: url('http://www.ruizhancq.com/common/news/icon_youjiantou_hui_60.png');
        display: none;
      }
      ul {
        transition: all 0.3s linear;
        li {
          width: 200px;
          height: 220px;
          img {
            width: 150px;
            height: 150px;
          }
        }
        li:not(:nth-of-type(1)){
          margin-left: 60px;
        }
      }
      .btn_p{
        width: 288px;
        height: 52px;
      }
      .w900{
        width: 900px;
      }
    }
    .online:hover{
      .prev{
        display: block;
      }
      .noprev{
        display: block;
      }
      .next{
        display: block;
      }
      .nonext{
        display: block;
      }
    }
    @media screen and (min-width: 750px) and (max-width: 1500px) {
      .online {
        height: 600px;
        ul {
          width: 1000px;
          li {
            width: 190px;
            height: 210px;
            img {
              width: 120px;
              height: 120px;
            }
          }
        }
      }
    }
    @media screen and (max-width: 750px){
      .online {
        height: 36.4583vw;
        padding: 8.8542vw 0px 5.2083vw 0px;
        .w900{
          width: 49vw;
        }
        ul {
          width: 52.0833vw;
          li {
            width: 9.8958vw;
            height: 10.9375vw;
            border-radius: 1.0417vw;
            img {
              width: 6.25vw;
              height: 6.25vw;
            }
          }
          li:not(:nth-of-type(1)){
            margin-left: 3.125vw;
          }
        }
        .prev{
          width: 3.125vw;
          height: 3.125vw;
          margin: 0px 2.0833vw;
        }
        .next{
          width: 3.125vw;
          height: 3.125vw;
          margin: 0px 2.0833vw;
        }
        .btn_p{
          width: 20vw; 
          height: 3vw;
        }
      }
    }

    .outline {
      width: 100%;
      height: 780px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      li {
        width: 25%;
        height: 380px;
        position: relative;
        padding-right: 20px;

        img {
          width: 100%;
          height: 100%;
        }

        .outText {
          position: absolute;
          bottom: 30px;
          left: 30px;
        }
      }

      li:nth-of-type(2n) {
        margin-top: 20px;
      }

      // li:nth-of-type(1),
      li:nth-of-type(6n+1) {
        height: 480px;
      }

      // li:nth-of-type(2),
      li:nth-of-type(6n+2) {
        height: 280px;
      }
    }
    @media screen and (min-width: 1650px) and (max-width: 2000px) {
      .outline {
        height: 700px;
        li {
          height: 340px;
        }
        // li:nth-of-type(1),
        li:nth-of-type(6n+1) {
          height: 440px;
        }

        // li:nth-of-type(2),
        li:nth-of-type(6n+2) {
          height: 240px;
        }
      }
    }
    @media screen and (min-width: 1450px) and (max-width: 1650px) {
      .outline {
        height: 620px;
        li {
          height: 300px;
        }
        // li:nth-of-type(1),
        li:nth-of-type(6n+1) {
          height: 400px;
        }

        // li:nth-of-type(2),
        li:nth-of-type(6n+2) {
          height: 200px;
        }
      }
    }
    @media screen and (min-width: 750px) and (max-width: 1450px) {
      .outline {
        height: 540px;
        li {
          height: 260px;
        }
        // li:nth-of-type(1),
        li:nth-of-type(6n+1) {
          height: 360px;
        }

        // li:nth-of-type(2),
        li:nth-of-type(6n+2) {
          height: 160px;
        }
      }
    }
    @media screen and (max-width: 750px) {
      .outline {
        height: 36.4583vw;
        li {
          height: 17.5vw;
          padding-right: 1.0417vw;
          .outText {
            position: absolute;
            bottom: 10px;
            left: 10px;
          }
        }
        li:nth-of-type(2n) {
          margin-top: 1.0417vw;
        }
        // li:nth-of-type(1),
        li:nth-of-type(6n+1) {
          height: 22.5vw;
        }

        // li:nth-of-type(2),
        li:nth-of-type(6n+2) {
          height: 12.5vw;
        }
      }
    }

    .onandOut {
      width: 100%;
      height: 880px;
      background: url("http://www.ruizhancq.com/common/index/jpg1.png") no-repeat;
      background-size: 100% 100%;
      ul {
        width: 534px;
        height: 440px;
        margin-left: 666px;
        li {
          width: 100%;
          height: 112px;
          padding: 16px 25px;
        }
      }
    }
    @media screen and (min-width: 1650px) and (max-width: 2000px) {
      .onandOut {
        height: 780px;
        ul {
          margin-left: 666px;
        }
        .mt130 {
          margin-top: 100px;
        }
      }
    }
    @media screen and (max-width: 1650px) {
      .onandOut {
        height: 680px;
        ul {
          margin-left: 566px;
        }
        .mt130 {
          margin-top: 70px;
        }
      }
    }
    @media screen and (max-width: 1650px) {
      .onandOut {
        height: 45.8333vw;
        ul {
          width: 27.8125vw;
          height: 22.9167vw;
          margin-left: 34.6875vw;
          li {
            height: 5.8333vw;
            padding: 0.8333vw 1.3021vw;
          }
        }
        .mt130 {
          margin-top: 6.7708vw;
        }
      }
    }
  }

  .programBox {
    width: 100vw;
    ul {
      width: 100%;
      padding: 0px 460px;
      li {
        height: 250px;
        > div:nth-of-type(2) {
          text-align: center;
          line-height: 30px;
        }
      }
    }
    .pl160{padding-left: 160px;}
    @media screen and (min-width: 1800px) and (max-width: 2000px) {
      ul {
        padding: 0px 360px;
      }
    }
    @media screen and (min-width: 1650px) and (max-width: 1800px) {
      ul {
        padding: 0px 200px;
      }
    }
    @media screen and (min-width: 750px) and (max-width: 1650px) {
      ul {
        padding: 0px 100px;
      }
    }
    @media screen and (max-width: 750px){
      .ft24{
        font-size: 6px;
      }
      .pl160{padding-left: 10.38vw;}
      ul {
        padding: 0px 18.75vw;
        li {
          height: 14.5833vw;
          > div:nth-of-type(2) {
            text-align: center;
            line-height: 10px;
          }
        }
      }
    }
  }

  .whyBox {
    width: 100vw;
    // height: 1030px;
    background: url("http://www.ruizhancq.com/common/index/jpg2.png") no-repeat;
    background-size: 100% 100%;
    .mt100{}
    .why {
      width: 100%;
      // padding: 0px 460px;
      > div:nth-of-type(1) {
        > div:nth-of-type(2) {
          margin: 0px 63px;
        }
        > div:not(:nth-of-type(2)) {
          width: 200px;
          height: 150px;
          border-radius: 100%;
        }
      }
      > div:nth-of-type(2) {
        ul {
          width: 312px;
          >li {
            width: 312px;
            height: 49px;
          }
          >li:not(:first-of-type) {
            margin-top: 62px;
          }
        }
        ul:nth-of-type(1){
          >li:nth-of-type(1),>li:nth-of-type(5){
            margin-right: -72px
          }
          >li:nth-of-type(2),>li:nth-of-type(4){
            margin-right: -24px
          }
          >li:nth-of-type(3){
            margin-right: 0px
          }
        }
        ul:nth-of-type(2){
          >li:nth-of-type(1),>li:nth-of-type(5){
            margin-left: -72px
          }
          >li:nth-of-type(2),>li:nth-of-type(4){
            margin-left: -24px
          }
          >li:nth-of-type(3){
            margin-left: 0px
          }
        }
        .earth {
          width: 580px;
          height: 480px;
          margin-top: 7px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    @media screen and (min-width: 1800px) and (max-width: 2000px) {
      .why {
        padding: 0px 360px;
      }
    }
    @media screen and (min-width: 1650px) and (max-width: 1800px) {
      .why {
        padding: 0px 200px;
      }
    }
    @media screen and (min-width: 750px) and (max-width: 1650px) {
      .why {
        padding: 0px 100px;
      }
    }
    @media screen and (max-width: 750px) {
      .mt100{margin-top: 7.8125vw;}
      .why {
        padding: 0px 18.75vw;
        .ft16{
          font-size: 6px;
        }
        > div:nth-of-type(1) {
          > div:nth-of-type(2) {
            margin: 0px 3.28125vw;
          }
          > div:not(:nth-of-type(2)) {
            width: 12.5vw;
            height: 9.375vw;
          }
        }
        > div:nth-of-type(2) {
          ul {
            width: 18.75vw;
            >li {
              width: 28.75vw;
              height: 4vw;
            }
            >li:not(:first-of-type) {
              margin-top: 3.2292vw;
            }
          }
          ul:nth-of-type(1){
            >li:nth-of-type(1),>li:nth-of-type(5){
              margin-right: -5.75vw
            }
            >li:nth-of-type(2),>li:nth-of-type(4){
              margin-right: -1.25vw
            }
            >li:nth-of-type(3){
              margin-right: 0px
            }
          }
          ul:nth-of-type(2){
            >li:nth-of-type(1),>li:nth-of-type(5){
              margin-left: -5.75vw
            }
            >li:nth-of-type(2),>li:nth-of-type(4){
              margin-left: -1.25vw
            }
            >li:nth-of-type(3){
              margin-left: 0px
            }
          }
          .earth {
            width: 40vw;
            height: 32vw;
            margin-top: 0.3646vw;
          }
        }
      }
    }
  }

  .progressBox {
    width: 100vw;
    height: 620px;
    .progress {
      ul:first-of-type {
        width: 1160px;
      }
      .dotLine {
        width: 1048px;
        height: 0px;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        border: 2px solid #4581fb;
        ul {
          width: 1064px;
          height: 12px;
          margin-top: -6px;
          margin-left: -6px;
          li {
            width: 12px;
            height: 12px;
            background: #4581fb;
            border-radius: 100%;
          }
        }
      }
      ul:nth-of-type(2) {
        li {
          width: 210px;
        }
      }
    }
  }
  @media screen and (max-width: 750px){
    .progressBox {
      height: 32.2917vw;
      .progress {
        ul:first-of-type {
          width: 60.4167vw;
        }
        .dotLine {
          width: 54.5833vw;
          border: 1px solid #4581fb;
          ul {
            width: 55.4167vw;
            height: 0.625vw;
            margin-top: -0.3125vw;
            margin-left: -0.3125vw;
            li {
              width: 0.625vw;
              height: 0.625vw;
            }
          }
        }
        ul:nth-of-type(2) {
          li {
            width: 10.9375vw;
          }
        }
      }
    }
  }

  .advantageBox {
    width: 100vw;
    height: 1460px;
    background: url("http://www.ruizhancq.com/common/index/jpg3.png") no-repeat;
    background-size: 100% 800px;
    ul {
      width: 1200px;
      li {
        > div:not(.line_b) {
          width: 200px;
          height: 150px;
        }
      }
    }
    .formBox {
      width: 1200px;
      height: 800px;
      padding: 0px 60px;
      .form {
        width: 1080px;
        .input {
          width: 520px;
          height: 56px;
        }
      }
      .subBtn {
        width: 400px;
        height: 60px;
      }
    }
  }
  @media screen and (max-width: 750px){
    .advantageBox {
      height: 84vw;
      background-size: 100% 41.6667vw;
      ul {
        width: 62.5vw;
        li {
          > div:not(.line_b) {
            width: 10.4167vw;
            height: 7.8125vw;
          }
        }
      }
      .formBox {
        width: 62.5vw;
        height: 48vw;
        padding: 0px 3.125vw;
        .form {
          width: 56.25vw;
          height: 24vw;
          .input {
            width: 27.0833vw;
            height: 2.9167vw;
            margin-bottom: 4px;
          }
        }
        .subBtn {
          width: 10.8333vw;
          height: 3.125vw;
        }
      }
    }
  }
}

::v-deep .el-radio__label {
  font-size: 20px;
}

::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #4581fb;
}

::v-deep .el-radio__inner {
  width: 20px;
  height: 20px;
  border: 1px solid #c9c8c8;
}

::v-deep .el-radio__inner:hover {
  border: 1px solid #4581fb;
}

::v-deep .el-radio__inner::after {
  width: 8px;
  height: 8px;
  background-color: #4581fb;
}

::v-deep .el-radio__input.is-checked .el-radio__inner {
  background-color: #ffffff;
  background: #ffffff;
  border: 2px solid #4581fb;
  border-color: #4581fb;
}
::v-deep .el-form-item__content{
    
}
::v-deep .el-input__inner.el-input__inner.el-input__inner {
  height: 56px;
  padding-left: 19px;
  background-color: #f5f5f5;
  border: 1px solid #e9e9e9;
  font-size: 20px;
}

::v-deep .el-carousel__indicator--horizontal.el-carousel__indicator--horizontal.el-carousel__indicator--horizontal {
  padding: 20px 5px !important;
}

::v-deep .el-carousel__indicator--horizontal .el-carousel__button.el-carousel__button {
  width: 40px !important;
  height: 5px !important;
}

.line_w {
  width: 55px;
  height: 3px;
  background-color: #fff;
}

.line_b {
  width: 20px;
  height: 0px;
  border: 1px solid #d9d9d9;
}

.left_dashed {
  width: 50px;
  height: 0px;
  border-width: 1px;
  border-style: dashed;
  border-image: linear-gradient(
      90deg,
      rgba(69, 129, 251, 1) 0%,
      rgba(69, 129, 251, 1) 11%,
      rgba(69, 129, 251, 0) 12%,
      rgba(69, 129, 251, 0) 15%,
      rgba(69, 129, 251, 0.9) 16%,
      rgba(69, 129, 251, 0.7) 26%,
      rgba(69, 129, 251, 0) 27%,
      rgba(69, 129, 251, 0) 32%,
      rgba(69, 129, 251, 0.6) 33%,
      rgba(69, 129, 251, 0.5) 44%,
      rgba(69, 129, 251, 0) 45%,
      rgba(69, 129, 251, 0) 50%,
      rgba(69, 129, 251, 0.4) 51%,
      rgba(69, 129, 251, 0.3) 61%,
      rgba(69, 129, 251, 0) 62%,
      rgba(69, 129, 251, 0) 67%,
      rgba(69, 129, 251, 0.2) 68%,
      rgba(69, 129, 251, 0.1) 79%,
      rgba(69, 129, 251, 0) 80%,
      rgba(69, 129, 251, 0) 85%
    )
    1 1;
}

.right_dashed {
  width: 50px;
  height: 0px;
  border: 1px dashed;
  border-image: linear-gradient(
      90deg,
      rgba(69, 129, 251, 0) 0%,
      rgba(69, 129, 251, 0) 20%,
      rgba(69, 129, 251, 0.1) 21%,
      rgba(69, 129, 251, 0.2) 32%,
      rgba(69, 129, 251, 0) 33%,
      rgba(69, 129, 251, 0) 38%,
      rgba(69, 129, 251, 0.3) 39%,
      rgba(69, 129, 251, 0.4) 49%,
      rgba(69, 129, 251, 0) 50%,
      rgba(69, 129, 251, 0) 55%,
      rgba(69, 129, 251, 0.5) 56%,
      rgba(69, 129, 251, 0.6) 67%,
      rgba(69, 129, 251, 0) 68%,
      rgba(69, 129, 251, 0) 73%,
      rgba(69, 129, 251, 0.7) 74%,
      rgba(69, 129, 251, 0.9) 84%,
      rgba(69, 129, 251, 0) 85%,
      rgba(69, 129, 251, 0) 88%,
      rgba(69, 129, 251, 1) 89%,
      rgba(69, 129, 251, 1) 100%
    )
    1 1;
}

.up_dashed {
  width: 0px;
  height: 150px;
  border: 1px dashed;
  border-image: linear-gradient(
      180deg,
      rgba(69, 129, 251, 1) 0%,
      rgba(69, 129, 251, 1) 4%,
      rgba(69, 129, 251, 0) 5%,
      rgba(69, 129, 251, 0) 7%,
      rgba(69, 129, 251, 1) 8%,
      rgba(69, 129, 251, 1) 12%,
      rgba(69, 129, 251, 0) 13%,
      rgba(69, 129, 251, 0) 15%,
      rgba(69, 129, 251, 0.9) 16%,
      rgba(69, 129, 251, 0.9) 20%,
      rgba(69, 129, 251, 0) 21%,
      rgba(69, 129, 251, 0) 23%,
      rgba(69, 129, 251, 0.9) 24%,
      rgba(69, 129, 251, 0.9) 28%,
      rgba(69, 129, 251, 0) 29%,
      rgba(69, 129, 251, 0) 31%,
      rgba(69, 129, 251, 0.8) 32%,
      rgba(69, 129, 251, 0.8) 36%,
      rgba(69, 129, 251, 0) 37%,
      rgba(69, 129, 251, 0) 39%,
      rgba(69, 129, 251, 0.7) 40%,
      rgba(69, 129, 251, 0.7) 44%,
      rgba(69, 129, 251, 0) 45%,
      rgba(69, 129, 251, 0) 47%,
      rgba(69, 129, 251, 0.6) 48%,
      rgba(69, 129, 251, 0.6) 52%,
      rgba(69, 129, 251, 0) 53%,
      rgba(69, 129, 251, 0) 55%,
      rgba(69, 129, 251, 0.5) 56%,
      rgba(69, 129, 251, 0.5) 60%,
      rgba(69, 129, 251, 0) 61%,
      rgba(69, 129, 251, 0) 63%,
      rgba(69, 129, 251, 0.4) 64%,
      rgba(69, 129, 251, 0.4) 68%,
      rgba(69, 129, 251, 0) 69%,
      rgba(69, 129, 251, 0) 71%,
      rgba(69, 129, 251, 0.3) 72%,
      rgba(69, 129, 251, 0.3) 76%,
      rgba(69, 129, 251, 0) 77%,
      rgba(69, 129, 251, 0) 78%,
      rgba(69, 129, 251, 0.2) 80%,
      rgba(69, 129, 251, 0.2) 84%,
      rgba(69, 129, 251, 0) 85%,
      rgba(69, 129, 251, 0) 87%,
      rgba(69, 129, 251, 0.1) 88%,
      rgba(69, 129, 251, 0.1) 89%,
      rgba(69, 129, 251, 0) 90%
    )
    1 1;
}

.up_solid {
  width: 2px;
  height: 46px;
  background: #4581fb;
}

@media screen and (max-width: 750px) {
  ::v-deep .el-carousel__indicator--horizontal.el-carousel__indicator--horizontal.el-carousel__indicator--horizontal {
    padding: 8px 2px !important;
  }

  ::v-deep .el-carousel__indicator--horizontal .el-carousel__button.el-carousel__button {
    width: 10px !important;
    height: 2px !important;
  }
  .line_w {
    width: 2.8646vw;
    height: 1px;
    background-color: #fff;
  }
  .line_b {
    width: 1.0416vw;
  }
  .left_dashed {
    width: 2.6042vw;
  }
  .right_dashed {
    width: 2.6042vw;
  }
  .up_dashed {
    height: 7.8125vw;
  }
  .up_solid {
    width: 1px;
    height: 2.3958vw;
  }

  ::v-deep .el-radio__label {
    font-size: 6px;
  }

  ::v-deep .el-radio__inner {
    width: 1.0417vw;
    height: 1.0417vw;
  }

  ::v-deep .el-radio__inner::after {
    width: 0.4167vw;
    height: 0.4167vw;
  }

  ::v-deep .el-radio__input.is-checked .el-radio__inner {
    border: 1px solid #4581fb;
    border-color: #4581fb;
  }

  ::v-deep .el-input__inner.el-input__inner.el-input__inner {
    height: 2.9167vw;
    padding-left: 0.9896vw;
    font-size: 6px;
  }
  ::v-deep .el-form-item__content{
    line-height: 10px !important;
  }
}
</style>
