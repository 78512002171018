import server from "@/utils/request";


// 发请求的操作封装成函数  mock

// 首页
export function getSeoList(data){
    return server({
        method:"POST",
        url:"/api/index/seo_list",
        data
    })
}

// 首页
export function getIndex(data){
    return server({
        method:"POST",
        url:"/api/index/index",
        data
    })
}
// 底部导航
export function bottomNav(data){
    return server({
        method:"POST",
        url:"/api/index/bottom_nav",
        data
    })
}
// 其他数据
export function otherVal(data){
    return server({
        method:"POST",
        url:"/api/index/other_val",
        data
    })
}
// 提交咨询
export function consultSub(data){
    return server({
        method:"POST",
        url:"/api/index/consult_sub",
        data
    })
}
// 全网营销
export function networkList(data){
    return server({
        method:"POST",
        url:"/api/index/network_list",
        data
    })
}
// 新媒体营销
export function newMediaList(data){
    return server({
        method:"POST",
        url:"/api/index/new_media_list",
        data
    })
}
// 新闻资讯
export function getNews(data){
    return server({
        method:"POST",
        url:"/api/index/news",
        data
    })
}
// 新闻详情
export function getNewsDetail(data){
    return server({
        method:"POST",
        url:"/api/index/content",
        data
    })
}
// 关于我们
export function getAbout(data){
    return server({
        method:"POST",
        url:"/api/index/about",
        data
    })
}