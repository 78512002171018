import { render, staticRenderFns } from "./MyOther.vue?vue&type=template&id=24b91ab8&"
import script from "./MyOther.vue?vue&type=script&lang=js&"
export * from "./MyOther.vue?vue&type=script&lang=js&"
import style0 from "./MyOther.vue?vue&type=style&index=0&id=24b91ab8&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports